import React from 'react'
import { Link } from 'react-router-dom'
const ProjectPopup = ({ title, description, link, color, image ,updateIndex}) => {

    return (
        <>
        <div className='popup'>
           

            <div className="project-card shadow-animation" style={{ backgroundColor: color }}>
            <button className='close-popup' onClick={()=>{
                updateIndex(-1)
            }}>X</button>
                <div className="text-container">
                    <h1>{title}</h1>
                    <p>{description}</p>

                    <Link to={link} onClick={()=>{window.scrollTo(0,0)}}>Explore More</Link>

                </div>

                <img className="image-bottom-right" src={image} alt="Not Found" />
            </div>
        </div>
        
        </>
    )
}

ProjectPopup.defaultProps = {
    title: "...",
    description: "..."
}


export default ProjectPopup
