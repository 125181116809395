import idea from "../images/idea.svg"
import CurrentActivity from "./CurrentActivity"
import right_nav from "../images/activity/right-nav.svg"
import left_nav from "../images/activity/left-nav.svg"
import { useState } from "react"
import image1 from "../images/activity/1.svg"
import image2 from "../images/intern.svg"
import image3 from "../images/volunteer.svg"
import { useEffect } from "react"
import Stat from "./Stat"
import bulb from "../images/bulb.svg"
import arrow from "../images/curly-arrow.svg"
import loop from "../images/pro-loop.svg"
import { Link } from "react-router-dom"
import TestimonialCard from "./TestimonialCard"
import { useNavigate } from "react-router-dom"
import { Helmet } from 'react-helmet-async';
import LeafletMap from "./LeafletMap"
import SEO from "./SEO"
import AssociationLogos from './AssociationLogos';
import VolunteerPartners from './VolunteerPartners';

const imageArray = [image1, image2, image3]
var currenVisibleIndex = 0;

const Home = ({ currentActivities }) => {

  const apiKey = process.env.REACT_APP_READ_API_TOKEN

  const navigate = useNavigate()
  const [maxTestiLoad, updateMaxLoad] = useState(6);
  useEffect(() => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${apiKey}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://cms.insightforinnovation.in/api/testimonials?populate=*", requestOptions)
      .then(response => response.text())
      .then(result => {
        updateTestimonials(JSON.parse(result).data);
      })
      .catch(error => console.log('error', error));

  }, [])

  const [testimonials, updateTestimonials] = useState([])

  const moveRight = () => {

    if (document.getElementById("activity-image-text")) {
      document.getElementById("activity-image-text").classList.remove("fade-in-fade-out")
      void document.getElementById("activity-image-text").offsetWidth;
      document.getElementById("activity-image-text").classList.add("fade-in-fade-out")
      setTimeout(() => {
        currenVisibleIndex++

        currenVisibleIndex = (currenVisibleIndex % currentActivities.length)

        updateVisibleActivity(currentActivities[currenVisibleIndex])
      }, 400)
    }


  }
  const moveLeft = () => {

    if (document.getElementById("activity-image-text")) {
      document.getElementById("activity-image-text").classList.remove("fade-in-fade-out")
      void document.getElementById("activity-image-text").offsetWidth;
      document.getElementById("activity-image-text").classList.add("fade-in-fade-out")
      setTimeout(() => {
        currenVisibleIndex--
        if (currenVisibleIndex < 0) {
          currenVisibleIndex = currentActivities.length - 1
        }
        currenVisibleIndex = (currenVisibleIndex % currentActivities.length)

        updateVisibleActivity(currentActivities[currenVisibleIndex])
      }, 400)
    }

  }

  const [currenVisibleIndexState, updateVisibleIndex] = useState(0)
  const [visibleActivity, updateVisibleActivity] = useState(currentActivities[0])


  useEffect(() => { updateVisibleActivity(currentActivities[currenVisibleIndex]) }, [currentActivities])

  return (
    <div className="home-container">
      <SEO
        title="Empowering Our Kids Through Education | NGO Trivandrum - Insight for Innovation"
        description="Insight for Innovation, an esteemed NGO based in Trivandrum, Kerala, is passionately dedicated to empowering underprivileged children through transformative education. Our organization strives to unleash the brilliance within every young heart, nurturing their dreams and creating a brighter tomorrow, one heartwarming tale of learning at a time. Join us as a volunteer or partner to make a transformative impact on underprivileged youth in Trivandrum, Kerala."

        canonicalEnd=""
      />
      <section className="hero-section">
        <div className="leftSection">
          <div>
            <h1 className="emp-heading">
              Empowering Our Kids Through Education
            </h1>
            <p className="emp-description">
              At Insight for Innovation, an NGO nested in Kerala, Trivandrum, we are focused on transformative educational initiatives that can truly nurture a child and enable them to transform into their full potential while also enabling the society around them. We believe in enabling people from every walk of society to take collective responsibility in raising a child.
            </p>
            <p className="emp-description">
              In our programs, we are focused on enabling a social-emotional learning integrated model of education to ensure that children not only excel academically but also develop resilience, empathy, and learnability skills.
            </p>
            <p className="emp-description">
              Our vision extends to the community, where we aim to bring society together through education. By instilling learnability skills, we enable children from underprivileged backgrounds to explore their full potential and possibilities.
            </p>
          </div>
          <div className="cta-container">
            <button className="primary_btn btn-large" onClick={() => { navigate("/projects"); window.scrollTo(0, 0) }}>Explore Projects </button>
          </div>

        </div><div className="rightSection">
          <img className="ideaImage" src={idea} alt="" />
        </div>
      </section>
      {visibleActivity && <section className="current-activities wrapper">
        <CurrentActivity title={visibleActivity.title} description={visibleActivity.description} link={visibleActivity.link} linkTitle={visibleActivity.linkTitle} image={imageArray[currenVisibleIndex % imageArray.length]} />
        <img className="right-nav" src={right_nav} alt="right-nav" onClick={moveRight} />
        <img className="left-nav" src={left_nav} alt="left-nav" onClick={moveLeft} />
        <div className="moving-circles-container">
          {currentActivities.map((item, index) => {
            return (
              <div className="circle" style={index == currenVisibleIndex ? { backgroundColor: "black" } : { backgroundColor: "white" }}>

              </div>
            )
          })}
        </div>
      </section>}

      <Stat />

      {/* <section className="our-associations">
        <h1>Our Associations</h1>
        <div className="logos" id="logos">
          <img src={azure} alt="logo" />
          <img src={north} alt="logo" />
          <img src={microsoft} alt="logo" />
          <img src={british} alt="logo" />
        </div>
      </section> */}
      <section className="wrapper">
        <div id="bulb" className="bulb-container">
          <img className="bulb" src={bulb} alt="bg" />
          <div className="vf-container">
            <h2 className="single-line h1">Our<br></br> Vision     &     Focus</h2>
            <div className="vf">
              <div>
                <h2 className="multi-line emp-heading">Our Vision</h2>
                <div className="v-text">
                  <img className="arrow-1" src={arrow} alt="arrow" />
                  We aim to create a culture where society can take collective responsibility to nurture and transform the younger generation through education
                </div>
              </div>
              <div>
                <h2 className="multi-line emp-heading">Our Focus</h2>
                <div className="f-text">
                  <img className="arrow-2" src={arrow} alt="arrow" />
                  To enable a parallel educational support system for underprivileged children and use guided volunteering as a tool towards bringing in people from different sections of society to fill the existing gaps in education
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="loop-img-container wrapper">
        <img className="loop-bg" src={loop} alt="bg" />
        <div className="p-spark square-p"><Link onClick={() => { window.scrollTo(0, 0) }} to="/projects/project-spark">Spark</Link></div>
        <div className="p-disha square-p"><Link onClick={() => { window.scrollTo(0, 0) }} to="/projects/project-disha">Disha</Link></div>
        <div className="p-ganitham square-p"><Link onClick={() => { window.scrollTo(0, 0) }} to="/projects/project-ganitham">Ganitham</Link></div>
        <div className="p-lets-learn square-p"><Link onClick={() => { window.scrollTo(0, 0) }} to="/projects/lets-learn-english">Let's Learn English</Link></div>
      </section>

      <section className="wrapper emp-description" style={{ fontFamily: "Montserrat" }}>
        <p>
          Nestled in the heart of Trivandrum, the capital city of Kerala, we are an esteemed NGO dedicated to making a lasting difference in our community. With our focus firmly fixed on the betterment of underprivileged children, Insight for Innovation serves as a catalyst for positive change. Through our projects, we strive to bridge the gap in educational opportunities, offering a helping hand to those who are less fortunate. Our dedicated team of volunteers and partners works tirelessly to ensure that every child has access to quality education, regardless of their socio-economic background.
          <br /><br />
          Join us in our noble mission to empower underprivileged children through education. Make a transformative impact with Insight for Innovation. Empower underprivileged children. Inspire a brighter future.
        </p>
      </section>

      <section id="map">
        <LeafletMap />
      </section>

      <AssociationLogos />
      <VolunteerPartners />

      <div className="test-load-cont">
        <h1>Testimonials</h1>
        <div className="testimonials-container">
          {testimonials.map((item, index) => {

            return (
              index < maxTestiLoad && <TestimonialCard name={item.attributes.author} mainText={item.attributes.testimonialText} pos={item.attributes.authorDesc} event={item.attributes.eventName} image={item.attributes.authorImage} />
            )
          })}
        </div>

        {maxTestiLoad < testimonials.length && <div class="load-more primary_btn" onClick={() => updateMaxLoad(testimonials.length)}>Load More</div>}
      </div>






    </div>
  )
}

export default Home
