import React from 'react'
import SEO from './SEO';

const About = () => {
    return (
        <div>
            <SEO
                title="About us | Insight for Innovation"
                description="Know more about our journey"

                canonicalEnd="about-us"
            />
            <div className='general-content wrapper'>
                <h1 className='center-text'>About Us</h1>
                <div className="top">
                    <div className="left">
                        Insight for Innovation (registered as a trust under Centre to promote Self Organised learning ) is an organisation working for leveraging the power of Education for transforming the society.
                        <br /><br />
                        With corporates and professionals mentoring college students, and college students in turn mentoring school students, we create a model through which society can contribute towards the growth of children. When education moves from being a money making tool to a process of happiness, true transformation of not just individuals but society happens.
                    </div>
                    <div className="right">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ZRr3SXAG_TA?feature=oembed"></iframe>

                    </div>
                </div>
                <div className="bottom">
                    Our team works on transformational and intensive projects like Disha through which children from socially and economically poor backgrounds are trained to not just have a better future for themselves, but to be leaders in making their society better.

                    We also work on projects like Ganitham through which thousands of volunteers get involved in teaching and mentoring school students in various aspects of academic and non academic education. With the help of our research team we are able to create multiple programs for students, teachers and parents to make learning a soulful experience.

                </div>
            </div>

        </div>
    )
}

export default About
