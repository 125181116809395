import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate()
  return (
    <div className="wrapper nav-offset error-404">
      <Helmet>
        <title>Page not found | 404 | Insight for Innovation</title>
      </Helmet>
      <h2>Page not found!</h2>
      <p>Uh oh! The page you were looking for does not exist.</p>
      <button className="primary_btn" onClick={()=>{navigate("/");window.scrollTo(0,0);}}>Take me home</button>
    </div>
  )
}

export default PageNotFound