
import contactUs from "../images/contact_us.svg"
import call from "../images/call.svg"
import email from "../images/email.svg"
import location from "../images/location.svg"
import { useState } from "react"
import { Helmet } from 'react-helmet-async';
import SEO from "./SEO"
const ContactUs = () => {

  const apiKey = process.env.REACT_APP_FORMS_API_TOKEN;

  function is_valid_email(email) {
    var pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/;
    return pattern.test(email);
  }

  const [name, changeName] = useState("")
  const [emailValue, changeEmail] = useState("")
  const [comments, changeComments] = useState("")
  const [error, updateError] = useState([])
  const [submitText, changeSubmit] = useState("Submit")
  const submit = (e) => {
    e.preventDefault();
    var errorList = []
    if (!is_valid_email(emailValue) && emailValue) {
      errorList.push("Please enter a valid email.")
    }
    if (!name) {
      errorList.push("Name cannot be blank.")
    }
    if (!emailValue) {
      errorList.push("Email cannot be blank.")
    }
    if (!comments) {
      errorList.push("Comments cannot be blank.")
    }

    updateError(errorList)
    if (errorList.length > 0) {
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${apiKey}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "data": {
        "fullName": name,
        "email": emailValue,
        "contactQuery": comments
      },
      "meta": {}
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://cms.insightforinnovation.in/api/contact-forms", requestOptions)
      .then(response => response.text())
      .then((response) => {
        updateError([]); changeSubmit("Submitted!"); changeName("")
        changeEmail("")
        changeComments("");
        setTimeout(() => {
          changeSubmit("Submit")

        }, 3000);
      })
      .catch(error => console.log('error', error));
  }
  return (
    <div className="wrapper">
      <SEO
        title="Contact Us | Insight for Innovation - Empowering Young Minds Through Education"
        description="Connect with us to join hands in empowering young minds through education. Let's work together to create a brighter future for underprivileged children."

        canonicalEnd="contact-us"
      />
      <div className="card-container nav-offset">
        <div className="main-content">
          <div className="heading-descr">
            <h1 className="contact-us-heading">Contact Us</h1>
            <p className="contact-us-des">Reach out, and let's make a difference together!</p>
          </div>
          <form className="contact-us" onSubmit={submit}>
            <input name="name" type="text" placeholder="Enter your name" value={name} onChange={(e) => { changeName(e.target.value) }} />
            <input name="email" type="text" placeholder="Enter your email" value={emailValue} onChange={(e) => { changeEmail(e.target.value) }} />
            <textarea name="comments" placeholder="Go ahead, we are listening..." id="" cols="30" rows="10" value={comments} onChange={(e) => { changeComments(e.target.value) }}></textarea>
            <div className="error">
              {error.map(item => <div>{item}</div>)}
            </div>
            <input type="submit" value={submitText} />
          </form>

        </div>

        <div className="image-section">
          <img className="contactUsImage" src={contactUs} alt="Contact Us" />
          <div className="contactInfo">
            <p><img className="contactIconImage" src={location} alt="Location" />Kadiyapattanam Road, Manacaud, Trivandrum, India</p>
            <p><img className="contactIconImage" src={call} alt="Call" />+91 7356256446</p>
            <p><img className="contactIconImage" src={email} alt="Email" />admin@insightforinnovation.in</p>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ContactUs
