const ActivityCard = ({ title, description, image, link, color}) => {
    return (
        
            <div className="activity-card box-shadow" style={{ backgroundColor: color }}>
                
                <div className="text-p">
                    <h3>{title}</h3>
                    <p>{description}</p>
                    {link&& <p className="know-more" onClick={()=>{window.open(link)}}>Know more</p>}
                </div>
                <div className="image-bottom-right">
                    <img src={image} alt=""/>
                </div>
            </div>
            
        
    )
}

export default ActivityCard
