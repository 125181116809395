import { Link, useNavigate } from "react-router-dom"

const OpporCard = ({heading,description,image,updateOppor,oppor}) => {
    const navigate = useNavigate()
    return (
        <div className="oppor-card box-shadow">
            
            <h1 className="oppor-card-heading">{heading}</h1>
            <p className="card-description">{description}</p>
            {/* <div className="oppor-card-btn" onClick={()=>{
                updateOppor(oppor)
            }}>Know more</div> */}
                <div className="oppor-card-btn" onClick={()=>{navigate("/opportunities/"+oppor);window.scrollTo(0,0);}}>Know more</div>
            <img src={image} alt="" className="oppor-image" />
        </div>
    )
}

export default OpporCard
