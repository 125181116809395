import React from 'react'
import SEO from './SEO'

const PPolicy = () => {
    return (
        <div className='general-content' style={{ padding: '20px' }}>
            <SEO
                title="Terms and Conditions | Insight for Innovation"
                description="Terms and Conditions"

                canonicalEnd="terms"
            />
            <h1>Terms and Conditions</h1>
            <p>Please read these Terms and Conditions (“Terms”) carefully before using the services provided by Insight for Innovation (“the Organization”). By accessing or using any services provided by the Organization, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you should not access or use the Organization’s services.</p>
            <div className="sub-content">
                <p>1. Organization’s Purpose:
                    <p className="sub-item-2">
                        Insight for Innovation is a non-profit organization dedicated to promoting and supporting innovation, creativity, and research for the betterment of society. The Organization aims to foster collaboration, provide resources, and create opportunities for individuals and organizations in various fields.
                    </p>
                </p>
                <p>2. Eligibility:<br />
                    <p className="sub-item-2">
                        <span className='index-text'>a.</span> Individuals or organizations of all ages, backgrounds, and nationalities are eligible to use the Organization’s services, provided they comply with these Terms.
                    </p>
                    <p className="sub-item-2">
                        <span className='index-text'>b.</span> The Organization reserves the right to refuse access to its services or terminate user accounts at its discretion.
                    </p>

                </p>
                <p>3. Use of Services:<br />
                    <p className="sub-item-2">
                        <span className='index-text'>a.</span> All services provided by the Organization, including but not limited to events, workshops, online platforms, and resources, are intended for lawful purposes and must be used responsibly and respectfully.
                    </p>
                    <p className="sub-item-2">
                        <span className='index-text'>b.</span> Users are responsible for their own actions and content while using the Organization’s services.
                    </p>

                    <p className="sub-item-2">
                        <span className='index-text'>c.</span> Users must not engage in any activities that may disrupt, damage, or impair the functioning of the Organization’s services or networks.
                    </p>

                    <p className="sub-item-2">
                        <span className='index-text'>d.</span> Users must not use the Organization’s services for any illegal, harmful, or unethical activities.
                    </p>

                    <p className="sub-item-2">
                        <span className='index-text'>e.</span> Users must not violate the rights of others or infringe upon any intellectual property rights.
                    </p>
                </p>
                <p>4. Privacy and Data Protection:
                    <p className="sub-item-2">
                        <span className='index-text'>a.</span> The Organization respects the privacy of its users. Any personal information collected by the Organization will be handled in accordance with applicable privacy laws and the Organization’s Privacy Policy.
                    </p>

                    <p className="sub-item-2">
                        <span className='index-text'>b.</span> Users are responsible for ensuring the accuracy and security of their personal information shared with the Organization.
                    </p>
                </p>
                <p>5. Intellectual Property:
                    <p className="sub-item-2">
                        <span className='index-text'>a.</span> The Organization’s name, logo, content, and any other intellectual property associated with the Organization are protected by copyright and other intellectual property laws.
                    </p>

                    <p className="sub-item-2">
                        <span className='index-text'>b.</span> Users must not reproduce, distribute, modify, or use any of the Organization’s intellectual property without prior written permission.
                    </p>
                </p>
                <p>6. External Links:
                    <p className="sub-item-2">
                        <span className='index-text'>a.</span> The Organization’s services may contain links to third-party websites or resources. These links are provided for convenience and do not imply endorsement or responsibility by the Organization.
                    </p>

                    <p className="sub-item-2">
                        <span className='index-text'>b.</span> The Organization is not liable for any content, products, or services provided by third-party websites or resources.
                    </p>
                </p>
                <p>7. Disclaimer of Liability:
                    <p className="sub-item-2">
                        <span className='index-text'>a.</span> The Organization strives to provide accurate and up-to-date information and services but does not guarantee their completeness, accuracy, or reliability.
                    </p>

                    <p className="sub-item-2">
                        <span className='index-text'>b.</span> The Organization shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with the use or inability to use its services.
                    </p>
                </p>
                <p>8. Modifications and Termination:
                    <p className="sub-item-2">
                        <span className='index-text'>a.</span> The Organization reserves the right to modify, suspend, or terminate any or all of its services at any time without notice.
                    </p>

                    <p className="sub-item-2">
                        <span className='index-text'>b.</span> The Organization may update these Terms from time to time, and it is the users’ responsibility to review them periodically.
                    </p>
                </p>
                <p>9. Governing Law:
                    <p className="sub-item-2">
                        These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising from these Terms or the Organization’s services shall be subject to the exclusive jurisdiction of the courts in [Jurisdiction].
                    </p>
                </p>
                <p>By accessing or using any services provided by Insight for Innovation, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</p>

            </div>
        </div>

    )
}

export default PPolicy
