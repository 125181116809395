import React from 'react';
import logo_placeholder from '../images/logos/logo_placeholder.png';
import petronet from '../images/logos/petronet-lng.png';
import district_admin from '../images/logos/district_admin.png';
import tata_elxsi from '../images/logos/tata-elxsi.png';
import ust from '../images/logos/ust-logo.png';

const AssociationLogos = () => {
  const associationLogos = [
    { name: 'Petronet LNG', project: 'Project Ganitham 2020', logo: petronet },
    { name: 'District Administration Thiruvananthapuram', project: 'Project Aksharam', logo: district_admin },
    { name: 'Tata Elxsi', project: 'Integrated Education Support Programme 2023-2026', logo: tata_elxsi },
    { name: 'UST', project: 'Project Ganitham, English and Ignite', logo: ust },
  ];

  return (
    <section className="association-logos wrapper">
      <h2>Associated Partners for Projects</h2>
      <div className="logos-container">
        {associationLogos.map((logo, index) => (
          <div key={index} className="logo-item">
            <img src={logo.logo || logo_placeholder} alt={logo.name} />
            <p>{logo.name}</p>
            <p>{logo.project}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AssociationLogos;