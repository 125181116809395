import { Link } from "react-router-dom"

const ProjectCard = ({title, description,link,color,image,index, updateIndex}) => {
    // title="PROJECT GANITHAM"
    // description="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis, deleniti iste est eligendi nesciunt optio cum mollitia modi harum vero dolorem enim autem quibusdam id, quidem inventore, doloremque incidunt distinctio."
    // link="/project_ganitham"
    // color="#EDC346"
  
  const clicked=()=>{
    updateIndex(index)
  }
  return (
    <>
    <div className="project-card box-shadow" style={{backgroundColor:color}}>
        <div className="main-content">
            <h3>{title}</h3>
            <div className="description-cta">
              <p>{description}</p>
              <div className="cta">
                <Link onClick={()=>{window.scrollTo(0,0)}} to={link}>Know More</Link>
              </div>
               
            </div>
            
        </div>
        <div className="image-bottom-right">
          <img src={image} alt="Not Found" />
        </div>
    </div>
  
    </>
  )
}

ProjectCard.defaultProps={
  title:"...",
  description:"..."
}


export default ProjectCard
