import { useState, useEffect } from "react";
import leftNav from "../images/team/left-nav.svg";
import rightNav from "../images/team/right-nav.svg";
import linkdin from "../images/team/linkdin.svg";
import Footer from "./Footer";
import { Helmet } from 'react-helmet-async';
import SEO from "./SEO";

const Team = () => {
    const handleKeyDown = (event) => {
        if (event.key === "ArrowRight") {
            document.getElementsByClassName("right-nav")[0].click();
        } else if (event.key === "ArrowLeft") {
            document.getElementsByClassName("left-nav")[0].click();
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, []);

    const [factArray, changeFact] = useState([...Array(13).keys()]);
    const [navdisabled, updateNavStat] = useState(false);
    const [shift, changeShift] = useState(9.3);

    const changeFactLeft = () => {
        if (navdisabled) {
            return;
        }
        document.getElementById("middle-member").className = "";
        document.getElementById("middle-member").classList.remove("middle-guy");
        void document.getElementById("middle-member").offsetWidth;
        document.getElementById("middle-member").classList.add("middle-guy");

        const modified = factArray.map(pos => (pos - 1 + 13) % 13);
        changeFact(modified);

        updateNavStat(true);
        setTimeout(() => { updateNavStat(false); }, 500);
    };

    const changeFactRight = () => {
        if (navdisabled) {
            return;
        }
        document.getElementById("middle-member").className = "";
        document.getElementById("middle-member").classList.remove("middle-guy-2");
        void document.getElementById("middle-member").offsetWidth;
        document.getElementById("middle-member").classList.add("middle-guy-2");

        const modified = factArray.map(pos => (pos + 1) % 13);
        changeFact(modified);

        updateNavStat(true);
        setTimeout(() => { updateNavStat(false); }, 500);
    };

    const teamInformation = [
        {
            "name": "Karthika Krishna",
            "description": "Karthika is our Group Project Coordinator. An Ex-Infoscion and a current Insightian would not be the ideal definition. She is our Genie- An exciting mix of soft-spoken and determined, always smiling and efficient and also cheeky badass ness. She is the one who leads our Coordinators for Ganitham and Once Upon a Time and is the answer to every who, what and where regarding the project.",
            "imageUrl": require("../images/team/2.png"),
            "link": "https://www.linkedin.com/in/karthika-krishna-3562a6147",
            "position": "Organisational Development Director"
        },
        {
            "name": "Sanjaykrishnan S",
            "description": "Sanjay is our Resource Generation Head. He is the one who brings in all of our volunteers, the one who inspires our volunteers and stands by them through thick and thin. He is also our Variyar Ammavan from Ravanprabhu – the one who keeps all of our Team Mohanlals grounded while also converting our visions into reality. An engineering graduate with an addiction to spreading smiles on little faces, he is the one in charge of Spark.",
            "imageUrl": require("../images/team/1.png"),
            "link": "https://www.linkedin.com/in/sanjaykrishnan-s-ab3854175",
            "position": "Executive Director"
        },
        {
            "name": "Fathima A",
            "description": "Well, I am the one who wrote all the Bios. I am the social impact coordinator and also the content creation head. I am in charge of impact analysis, research, data and content management for all our projects. I convert our efforts into tangible outcomes and ensure that they reach the right people so that we can spread our wings into farther horizons.",
            "imageUrl": require("../images/team/8.png"),
            "link": "https://www.linkedin.com/in/fathima-a-b38aaa157",
            "position": "Social Impact Director"
        },
        {
            "name": "Remi Raman",
            "description": "Remi is our Programme Development Lead, bringing fresh ideas and strategies to enhance our educational initiatives.",
            "imageUrl": require("../images/team/remi.png"),
            "link": "https://www.linkedin.com/in/remi-raman-240368166",
            "position": "Programme Development Lead"
        },
        {
            "name": "Gouri Raman",
            "description": "Gouri leads our Programme Management team and provides legal counsel, ensuring smooth operations and compliance in all our activities.",
            "imageUrl": require("../images/team/gauri.png"),
            "link": "https://www.linkedin.com/in/gouri-raman-aa56a8160",
            "position": "Lead - Programme Management & Legal Counsel"
        },
        {
            "name": "Hema Gopalakrishnan",
            "description": "She is the Founder and CEO of Insight, but that's just a title. Her actual job is as the Analyst – Analysing ideas, projects, people, and emotions. She is our idea-head (quite literally), chief inspirer, 'Get out of your comfort zone' motivator and the vision behind Insight. She aspires to retire soon ( shhh- secret- we are not gonna let her)",
            "imageUrl": require("../images/team/9.png"),
            "link": "https://www.linkedin.com/in/hema-gopalakrishnan-b63531205",
            "position": "Founder & CEO"
        },

        {
            "name": "Akhil J",
            "description": "Akhil works on community engagement and program coordination, helping to implement our initiatives effectively.",
            "imageUrl": require("../images/team/akhil.png"),
            "link": "https://www.linkedin.com/in/akhil-j-0252b822a",
            "position": "Community Engagement & Programme Coordinator"
        },
        {
            "name": "Alitta Jose",
            "description": "Alitta handles data evaluation and program coordination, ensuring our projects are data-driven and impactful.",
            "imageUrl": require("../images/team/alitta.png"),
            "link": "https://www.linkedin.com/in/alitta-jose-b19b86248",
            "position": "Data Evaluation & Programme Coordinator"
        },
        {
            "name": "Akshaya K S",
            "description": "Akshaya focuses on volunteer development and program coordination, nurturing our volunteer base and enhancing their skills.",
            "imageUrl": require("../images/team/akshaya.png"),
            "link": "https://www.linkedin.com/in/akshaya-k-s-990501307",
            "position": "Volunteer Development & Programme Coordinator"
        },
        {
            "name": "Devika P S",
            "description": "Our Training Head, she has been with us through thick and thin from the beginning of time (Insight's time), the one who has trained more than 5,000 of our volunteers and continues to do it. She is the one who gets magic done behind the screen but acts invisible. She is currently pursuing her Engineering degree and continues to prove that age is, alas, just a number.",
            "imageUrl": require("../images/team/5.png"),
            "link": "https://www.linkedin.com/in/devika-p-s-0aa169206",
            "position": "Academic Content & Programme Coordinator"
        },
        {
            "name": "Govind G Kannan",
            "description": "He is the one behind the lens, our photographer, videographer, poster creator, digital promotion lead and a beehive of ideas on how to improve our online footprint and generate the maximum impact of our projects digitally. His goofy smile and always-revolving head of ideas are the sunshine of every content meeting.?",
            "imageUrl": require("../images/team/10.png"),
            "link": "https://www.linkedin.com/in/govind-g-kannan-ggk-b1041124a",
            "position": "Social Media Manager for Change"
        },
        {
            "name": "Gokul Krishna A",
            "description": "He is our Skill development head. The one in charge of volunteer development, sustenance and upskilling. He is the usually soft-spoken, but thattel keriyaal mattu maarum (when put on a stage, he is the firebrand) person of our team and ensures that the fire is passed on to all our volunteers in the most awesome measure.",
            "imageUrl": require("../images/team/7.png"),
            "link": "https://www.linkedin.com/in/gokuulkris",
            "position": "Outreach coordinator"
        },
        {
            "name": "Goutham Raj",
            "description": "Goutham is responsible for community engagement and program coordination, fostering strong relationships with our stakeholders.",
            "imageUrl": require("../images/team/gautham.png"),
            "link": "https://www.linkedin.com/in/gowtham-raj-g-s-2a54091b9",
            "position": "Community Engagement & Programme Coordinator"
        }
    ];

    const [team, updateTeam] = useState(teamInformation);

    return (
        <section className="team nav-offset">
            <SEO
                title="Meet Our Team | Empowering Our Kids Through Education | Insight for Innovation"
                description="Get to know the dedicated and passionate team behind Insight for Innovation. From resource generation to skill development, our diverse and talented individuals work together to empower young minds, making a positive impact through education. Learn more about the faces driving the transformative change in our community."
                canonicalEnd="team"
            />
            <div className="team-heading">
                <h1>Strength in Unity</h1>
                <p>Introducing the passionate and skilled members of our team</p>
            </div>
            <div className="team-container">
                {
                    team.map((item, index) => {
                        return !(shift * factArray[index] < 0 || shift * factArray[index] > shift * 10 || (index === factArray.indexOf(5))) ? 
                        <div className={index % 2 === 0 ? "variation-left-container" : "variation-right-container"} style={{ left: `${(shift * factArray[index])}%` }}>
                            <img src={item.imageUrl} alt="Team member" />
                        </div> : 
                        <div className={index % 2 === 0 ? "variation-left-container" : "variation-right-container"} style={{ opacity: 0, left: `${(shift * factArray[index])}%` }}>
                            <img src={item.imageUrl} alt="Team member" />
                        </div>;
                    })
                }
                <img className="middle-guy" id="middle-member" src={team[factArray.indexOf(5)].imageUrl} alt="Team mumber" />
            </div>
            <div className="team-bottom-section">
                <img className="left-nav" src={leftNav} alt="Left" onClick={changeFactLeft} />
                <div className="middle-section">
                    <h1>{team[factArray.indexOf(5)].name}</h1>
                    <p style={{ fontSize: "20px", textTransform: "capitalize" }}>{team[factArray.indexOf(5)].position}</p>
                    <img src={linkdin} alt="Linkdin" className="linkdin" onClick={() => { window.open(team[factArray.indexOf(5)].link); }} />
                </div>
                <img className="right-nav" src={rightNav} alt="Right" onClick={changeFactRight} />
            </div>
        </section>
    );
};

export default Team;
