import { Link } from "react-router-dom"
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import { MapContainer, TileLayer, useMap, Marker, Tooltip, Popup } from 'react-leaflet'
import yellowMapMarker from '../images/map-marker-yellow.svg';
import pinkMapMarker from '../images/map-marker-pink.svg'
import blueMapMarker from '../images/map-marker-blue.svg'
import aquaMapMarker from '../images/map-marker-aqua.svg'
import greenMapMarker from '../images/map-marker-green.svg'
import { useMemo, useState, useRef } from "react";


// Leaflet Map
let DefaultIcon = L.icon({
  iconUrl: yellowMapMarker,
  shadowSize: [5],
  iconSize: [20, 20],
  iconAnchor: null
});

let yellowIcon = L.icon({
  iconUrl: yellowMapMarker,
  shadowSize: [5],
  iconSize: [20, 20],
  iconAnchor: null
});

let blueIcon = L.icon({
  iconUrl: blueMapMarker,
  shadowSize: [5],
  iconSize: [20, 20],
  iconAnchor: null
});

let aquaIcon = L.icon({
  iconUrl: aquaMapMarker,
  shadowSize: [5],
  iconSize: [20, 20],
  iconAnchor: null
});

let pinkIcon = L.icon({
  iconUrl: pinkMapMarker,
  shadowSize: [5],
  iconSize: [20, 20],
  iconAnchor: null
});

let greenIcon = L.icon({
  iconUrl: greenMapMarker,
  shadowSize: [5],
  iconSize: [20, 20],
  iconAnchor: null
});


L.Marker.prototype.options.icon = DefaultIcon;

const tooltipStyle = {
  display: "flex",
  flexDirection: "column",
}

const tooltipheadingStyle = {
  fontFamily: "Rowdies",
  fontSize: "16px"
}

const detailsWrapperStyle = {
  display: "flex",
  gap: "10px"
}

const detailStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "2px"
}

const detailHeadingStyle = {
  fontFamily: "Montserrat",
  fontSize: "10px",
  fontWeight: "600",
  textTransform: "uppercase"
}

const detailContentStyle = {
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: "800",
}

const detailCtaStyle = {
  display: "inline-block",
  padding: "3px",
  fontFamily: "Rowdies",
  fontSize: "12px",
  fontWeight: "400",
  textDecoration: "none",
  backgroundColor: "#F9A11F",
  color: "#222",
  borderRadius: "5px",
  border: "2px solid #222",
  boxShadow: "2px 2px #222",
  marginTop: "6px"
}

const projectMetadata = {
  pg:
  {
    "title": "Project Ganitham",
    "url": "/projects/project-ganitham",
    "icon": yellowIcon
  },
  ps:
  {
    "title": "Project Spark",
    "url": "/projects/project-spark",
    "icon": aquaIcon
  },
  lle:
  {
    "title": "Project Let's Learn English",
    "url": "/projects/lets-learn-english",
    "icon": pinkIcon
  },
  pd:
  {
    "title": "Project Disha",
    "url": "/projects/project-ganitham",
    "icon": pinkIcon
  },
  ouat:
  {
    "title": "Project Once Upon A Time",
    "url": null,
    "icon": pinkIcon
  }
  ,
  htl:
  {
    "title": "How to Learn",
    "url": null,
    "icon": blueIcon
  },
  iesp: {
    "title": "IESP",
    "url": "/projects/project-iesp",
    "icon": greenIcon
  }
}

const projectMarkers = [
  //Project Ganitham
  {
    title: "pg",
    school: "SNGHSS Chempazhanthy",
    district: "Trivandrum",
    coordinates: [8.5675123, 76.9112633]
  },
  {
    title: "pg",
    school: "St Thomas HSS, Amboori(Aided)",
    district: "Trivandrum",
    coordinates: [8.5041157, 77.1906269]
  },
  {
    title: "pg",
    school: "Govt HSS, Medical College",
    district: "Trivandrum",
    coordinates: [8.5163804, 76.9276491]
  },
  {
    title: "pg",
    school: "Govt Girls VHSS, Pettah",
    district: "Trivandrum",
    coordinates: [8.4955654, 76.9348838]
  },
  {
    title: "pg",
    school: "SMV Govt Model HSS",
    district: "Trivandrum",
    coordinates: [8.4891446, 76.9474863]
  },
  {
    title: "pg",
    school: "Govt City VHSS, PMG",
    district: "Trivandrum",
    coordinates: [8.5091972, 76.9475363]
  },
  {
    title: "pg",
    school: "Govt Boys HSS, Pettah",
    district: "Trivandrum",
    coordinates: [8.4947932, 76.9344814]
  },
  {
    title: "pg",
    school: "Govt VHSS Vattenad",
    district: "Palakkad",
    coordinates: [10.7609195, 76.1209705]
  },
  {
    title: "pg",
    school: "GVHSS Anakkara",
    district: "Palakkad",
    coordinates: [10.8100819, 76.0495736]
  },
  {
    title: "pg",
    school: "MSHSS, Ranni",
    district: "Pathanamthitta",
    coordinates: [9.3760800, 76.7790862]
  },
  {
    title: "pg",
    school: "HSS Perunad, Ranni",
    district: "Pathanamthitta",
    coordinates: [9.3661797, 76.8562782]
  },
  {
    title: "pg",
    school: "HSS Chalissery",
    district: "Palakkad",
    coordinates: [10.7334342, 76.0799867]
  },
  {
    title: "pg",
    school: "GHSS Nagalassery",
    district: "Palakkad",
    coordinates: [10.7616681, 76.1366331]
  },
  {
    title: "pg",
    school: "GHSS Kodumunda",
    district: "Palakkad",
    coordinates: [10.8226306, 76.1534140]
  },
  {
    title: "pg",
    school: "GHSS Tholicode",
    district: "Trivandrum",
    coordinates: [8.6470850, 77.0520671]
  },
  {
    title: "pg",
    school: "GVHSS Vellanad",
    district: "Trivandrum",
    coordinates: [8.5625039, 77.0546998]
  },

  //Project Spark
  {
    title: "ps",
    school: "Nethaji HSS",
    district: "Pathanamthitta",
    coordinates: [9.2473555, 76.7950075]
  },
  {
    title: "ps",
    school: "Christ Nagar",
    district: "Trivandrum",
    coordinates: [8.5142309, 76.9592602]
  },
  {
    title: "ps",
    school: "Holy Angels, Nandthancode",
    district: "Trivandrum",
    coordinates: [8.5150529, 76.9550726]
  },

  //Project Disha
  {
    title: "pd",
    school: "CMS Pallom",
    district: "Kottayam",
    coordinates: [9.5325160, 76.5156346]
  },
  {
    title: "pd",
    school: "CMS Kanam",
    district: "Kottayam",
    coordinates: [9.5432918, 76.6968175]
  },
  {
    title: "pd",
    school: "GTHS Idinjar",
    district: "Trivandrum",
    coordinates: [8.7534952, 77.0706095]
  },
  {
    title: "pd",
    school: "Poojapura UPS",
    district: "Trivandrum",
    coordinates: [8.4904641, 76.9726241]
  },

  //Project Once Upon A Time
  {
    title: "ouat",
    school: "Medical College School",
    district: "Kozhikode",
    coordinates: [11.2718206, 75.8340414]
  },
  {
    title: "ouat",
    school: "GHSS, Kaaraparamba",
    district: "Kozhikode",
    coordinates: [11.2871156, 75.7801858]
  },
  {
    title: "ouat",
    school: "Govt DVHSS, Charamangalam",
    district: "Alappuzha",
    coordinates: [9.6396998, 76.3429232]
  },
  {
    title: "ouat",
    school: "Nethaji, HSS",
    district: "Pathanamthitta",
    coordinates: [9.2473555, 76.7950075]
  },
  {
    title: "ouat",
    school: "CMS Pallom",
    district: "Kottayam",
    coordinates: [9.5325160, 76.5156346]
  },

  //How to Learn
  {
    title: "htl",
    school: "GHSS Manacaud",
    district: "Trivandrum",
    coordinates: [8.4670373, 76.9639005]
  },
  {
    title: "htl",
    school: "GHSS Mannanthala",
    district: "Trivandrum",
    coordinates: [8.5387355, 76.9370680]
  },
  {
    title: "htl",
    school: "GHSS Sreekaryam",
    district: "Trivandrum",
    coordinates: [8.5513365, 76.9110649]
  },
  {
    title: "htl",
    school: "Nethaji HSS",
    district: "Pathanamthitta",
    coordinates: [9.2473555, 76.7950075]
  },
  {
    title: "htl",
    school: "Amritha Thiruvalla",
    district: "Pathanamthitta",
    coordinates: [9.3867448, 76.5762652]
  },
  {
    title: "htl",
    school: "Amritha Vallikavu",
    district: "Kollam",
    coordinates: [9.0880476, 76.4877020]
  },
  {
    title: "htl",
    school: "Stratford",
    district: "Kollam",
    coordinates: [9.0036654, 76.5596059]
  },
  {
    title: "iesp",
    school: "Dr Ambedkar Memorial Girls School",
    district: "Kerala",
    coordinates: [8.5313834, 76.8996583]
  },
  {
    title: "iesp",
    school: "SNV Higher Secondary School",
    district: "Kerala",
    coordinates: [8.6322356, 77.0067182]
  },
  {
    title: "iesp",
    school: "Prem Nazir Memorial Government Higher Secondary School",
    district: "Kerala",
    coordinates: [8.662629, 76.7973099]
  },
  {
    title: "iesp",
    school: "Divine Children's Home",
    district: "Kerala",
    coordinates: [8.486054, 76.987258]
  },
  {
    title: "iesp",
    school: "Government High School",
    district: "Kerala",
    coordinates: [8.6879046, 77.1054573]
  },
  {
    title: "iesp",
    school: "Government Tribal High School",
    district: "Kerala",
    coordinates: [8.7533622, 77.0709891]
  },
  {
    title: "iesp",
    school: "St. Mary's Higher Secondary School",
    district: "Kerala",
    coordinates: [8.4940492, 76.9035133]
  },
  {
    title: "iesp",
    school: "Fort Girls Mission High School",
    district: "Kerala",
    coordinates: [8.4851602, 76.9437294]
  },
  {
    title: "iesp",
    school: "Govt. Higher Secondary School. Thonnakkal",
    district: "Kerala",
    coordinates: [8.6515707, 76.8575584]
  },
  {
    title: "iesp",
    school: "Haji CHMKM VHSE School",
    district: "Kerala",
    coordinates: [8.4723002, 76.9260519]
  },
  {
    title: "iesp",
    school: "Karthika Thirunnal Government Vocational & Higher Secondary School for Girls",
    district: "Kerala",
    coordinates: [8.4743694, 76.9469526]
  },
  {
    title: "iesp",
    school: "Government Vocational Higher Secondary School, Veeranakavu",
    district: "Kerala",
    coordinates: [8.5206448, 77.1106719]
  },
  {
    title: "iesp",
    school: "Sree Chitra Home",
    district: "Kerala",
    coordinates: [8.4865606, 76.9475693]
  },
  {
    title: "iesp",
    school: "Mcc hss Kozhikode",
    district: "Kerala",
    coordinates: [11.6413559, 75.7843506]
  },
  {
    title: "iesp",
    school: "SRV Government Higher Secondary School",
    district: "Kerala",
    coordinates: [9.9702873, 76.2857863]
  },
  {
    title: "iesp",
    school: "Government Higher Secondary School North Paravur (Boys School)",
    district: "Kerala",
    coordinates: [10.1475106, 76.2253406]
  },
  {
    title: "iesp",
    school: "Govt. DVHSS Charamangalam",
    district: "Kerala",
    coordinates: [9.638753, 76.3434551]
  },
  {
    title: "iesp",
    school: "Govt VHSS, Mangayil, Maradu",
    district: "Kerala",
    coordinates: [9.9366099, 76.3270868]
  },
  {
    title: "iesp",
    school: "Mor Severios Higher Secondary School(MSHSS)",
    district: "Kerala",
    coordinates: [9.376495, 76.779542]
  },
  {
    title: "iesp",
    school: "High School Ranni-Perunad",
    district: "Kerala",
    coordinates: [9.3653201, 76.859599]
  },
  {
    title: "iesp",
    school: "Ebenezer Higher Secondary school.( Aided)",
    district: "Kerala",
    coordinates: [9.4093397, 76.7789157]
  },
  {
    title: "iesp",
    school: "CMS High School",
    district: "Kerala",
    coordinates: [9.5319521, 76.5161254]
  },
  {
    title: "iesp",
    school: "B V M H S KALLETTUMKARA",
    district: "Kerala",
    coordinates: [10.3460844, 76.2769861]
  },
  {
    title: "iesp",
    school: "St Georges Convent School, Karanchira",
    district: "Kerala",
    coordinates: [10.3947812, 76.1637893]
  },
  {
    title: "iesp",
    school: "Mahatma LP&UP School Ground",
    district: "Kerala",
    coordinates: [10.3674447, 76.2058671]
  },
  {
    title: "iesp",
    school: "St.Joseph's Girls High School",
    district: "Kerala",
    coordinates: [10.400041, 76.2193428]
  },
  {
    title: "iesp",
    school: "RHS Thumboor",
    district: "Kerala",
    coordinates: [10.300867, 76.2560965]
  },
  {
    title: "iesp",
    school: "Little Flower Convent Higher Secondary School, Irinjalakuda",
    district: "Kerala",
    coordinates: [10.3468426, 76.2144595]
  },
  {
    title: "iesp",
    school: "L.B.S.M.H.S School",
    district: "Kerala",
    coordinates: [10.334566, 76.2401712]
  },
  {
    title: "iesp",
    school: "A U P S THUMBOOR",
    district: "Kerala",
    coordinates: [10.3007968, 76.2556351]
  },
  {
    title: "iesp",
    school: "Govt. Model Boys Higher Secondary School",
    district: "Kerala",
    coordinates: [10.347242, 76.2123709]
  },
  {
    title: "iesp",
    school: "GUPS VELLANGALLUR",
    district: "Kerala",
    coordinates: [10.2961065, 76.221405]
  },
  {
    title: "iesp",
    school: "Government Professional Higher Secondary School For Girls Nadakkavu",
    district: "Kerala",
    coordinates: [11.2704889, 75.77588]
  },
  {
    title: "iesp",
    school: "Government Higher Secondary School Karaparamba",
    district: "Kerala",
    coordinates: [11.2871132, 75.7804713]
  },
  {
    title: "iesp",
    school: "Pantheerankave Higher Secondary School 10190",
    district: "Kerala",
    coordinates: [11.227411, 75.8487051]
  },
  {
    title: "iesp",
    school: "Kambiliparamba A.M.U.P school",
    district: "Kerala",
    coordinates: [11.2266623, 75.8242371]
  },
  {
    title: "iesp",
    school: "Govt VHSS Payyanakkal",
    district: "Kerala",
    coordinates: [11.220918, 75.7872368]
  },
  {
    title: "iesp",
    school: "Kunduparampa Government Higher Secondary School",
    district: "Kerala",
    coordinates: [11.3083215, 75.777749]
  },
  {
    title: "iesp",
    school: "Governmet Fisheries HSS Puthiyappa,Kozhikode,PIN:673021",
    district: "Kerala",
    coordinates: [11.314164, 75.751457]
  },
  {
    title: "iesp",
    school: "Govt HSS Koduvally",
    district: "Kerala",
    coordinates: [11.3547975, 75.912943]
  },
  {
    title: "iesp",
    school: "SNHSS Uzhamalakkal",
    district: "Kerala",
    coordinates: [8.5953359, 77.0540178]
  },
  {
    title: "iesp",
    school: "SMV Govt.Model Higher Secondary School",
    district: "Kerala",
    coordinates: [8.4890917, 76.9478945]
  },
  {
    title: "iesp",
    school: "St. Roch's Convent school,",
    district: "Kerala",
    coordinates: [8.4770272, 76.9165137]
  },
  {
    title: "iesp",
    school: "Govt. H S Kattachakonam",
    district: "Kerala",
    coordinates: [8.5386225, 76.9372086]
  },
  {
    title: "iesp",
    school: "Mannanthala Govt H S",
    district: "Kerala",
    coordinates: [8.5568431, 76.943729]
  },
  {
    title: "iesp",
    school: "Medical College Govt. Vocational Higher Secondary School",
    district: "Kerala",
    coordinates: [9.6326341, 76.515493]
  },
  {
    title: "iesp",
    school: "Government High School, Karikkakom",
    district: "Kerala",
    coordinates: [8.496633, 76.9105783]
  },
  {
    title: "iesp",
    school: "Government Higher Secondary School for Boys - Pettah",
    district: "Kerala",
    coordinates: [8.4953044, 76.9345099]
  },
  {
    title: "iesp",
    school: "Pettah Girls HS School",
    district: "Kerala",
    coordinates: [8.49581, 76.9346447]
  },
  {
    title: "iesp",
    school: "St.Thomas Higher Secondary School",
    district: "Kerala",
    coordinates: [8.503517, 77.1918338]
  },
  {
    title: "iesp",
    school: "High School Vavode (HS)",
    district: "Kerala",
    coordinates: [8.5166377, 77.1463874]
  },
  {
    title: "iesp",
    school: "Govt: High School Utharamcode Pin:695574",
    district: "Kerala",
    coordinates: [8.574743, 77.1191143]
  },
  {
    title: "iesp",
    school: "Government Higher Secondary School",
    district: "Kerala",
    coordinates: [8.6467304, 77.0525786]
  },
  {
    title: "iesp",
    school: "Sri Ayyankali Memorial Government Model Residential Sports School",
    district: "Kerala",
    coordinates: [8.4280803, 76.985668]
  },
  {
    title: "iesp",
    school: "G.Karthikeyan Smaraka Government Higher Secondary School.",
    district: "Kerala",
    coordinates: [8.5623732, 77.0548738]
  },
  {
    title: "iesp",
    school: "Kachani Government High School",
    district: "Kerala",
    coordinates: [8.5513593, 76.9932874]
  },
  {
    title: "iesp",
    school: "Govt. City Vocational Higher Secondary School (CITY VHSS)",
    district: "Kerala",
    coordinates: [8.5091105, 76.948042]
  },
  {
    title: "iesp",
    school: "Point 59",
    district: "Other",
    coordinates: [22.2079078, 84.540467]
  },
  {
    title: "iesp",
    school: "Government Higher Primay School",
    district: "Bengaluru",
    coordinates: [13.00043778, 77.5522061]
  },
]

const tooltipMarker = projectMarkers.find((marker) => (marker.school == "Govt DVHSS, Charamangalam"))


const LeafletMap = () => {
  const [showTootip, setShowTootip] = useState(true)
  const eventHandlers = useMemo(
    () => ({
      click() {
        setShowTootip(false)
      },
    }),
    [],
  )


  return (
    <MapContainer center={[11.65, 78.01]} zoom={7.4} scrollWheelZoom={false} dragging={!L.Browser.mobile} touchZoom={true}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {/* {showTootip && 
          <Marker position={tooltipMarker.coordinates} icon={projectMetadata[tooltipMarker.title].icon}>
              <Tooltip style={tooltipStyle} interactive={true} anchor={['center', 'top']} permanent>
               <div style={tooltipheadingStyle}>
                {projectMetadata[tooltipMarker.title].title}
               </div>
               <div style={detailsWrapperStyle}>
                {tooltipMarker.school && <div style={detailStyle}>
                  <div style={detailHeadingStyle}>
                    School
                  </div>
                  <div style={detailContentStyle}>
                    {tooltipMarker.school}<br/>
                    {tooltipMarker.district}
                  </div>
                </div>}
               </div>
               <div className="cta">
                  <div>
                    <Link onClick={() => { window.scrollTo(0, 0) }} to={projectMetadata[tooltipMarker.title].url} style={detailCtaStyle}>More Details</Link>
                  </div>
                </div>
              </Tooltip>
            </Marker>} */}
      {projectMarkers.map((marker) => (
        <Marker position={marker.coordinates} icon={projectMetadata[marker.title].icon} eventHandlers={eventHandlers}>
          <Popup style={tooltipStyle} interactive={true}>
            <div style={tooltipheadingStyle}>
              {projectMetadata[marker.title].title}
            </div>
            <div style={detailsWrapperStyle}>
              {marker.school && <div style={detailStyle}>
                <div style={detailHeadingStyle}>
                  School
                </div>
                <div style={detailContentStyle}>
                  {marker.school}<br />
                  {marker.district}
                </div>
              </div>}
            </div>
            {projectMetadata[marker.title].url != null ?
              <div className="cta">
                <div>
                  <Link onClick={() => { window.scrollTo(0, 0) }} to={projectMetadata[marker.title].url} style={detailCtaStyle}>More Details</Link>
                </div>
              </div>
              :
              ""
            }
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  )
}
export default LeafletMap