import ImageCard from "./ImageCard"
import { Helmet } from 'react-helmet-async';
import SEO from "./SEO";
const Gallary = ({ galleryDetails }) => {
  const roateAr = [-6.134, 4.69, -2.089, 5.874]


  return (
    <>
      <div className="team-heading">
        <h1>Gallery</h1>
        <p>Relive the joy, hope, and transformation of our ventures through captivating snapshots</p>
      </div>
      <div className='gallery-container'>
        <SEO
          title="Gallery | Insight for Innovation - Empowering Young Minds Through Education"
          description="Step into the heartwarming world of Insight for Innovation through our captivating gallery. Experience the transformative moments of empowerment as young minds bloom and create a brighter future."

          canonicalEnd="gallery"
        />
        <Helmet>
          <title>Gallery | Insight for Innovation - Empowering Young Minds Through Education</title>
          <meta name='description' content="Step into the heartwarming world of Insight for Innovation through our captivating gallery. Experience the transformative moments of empowerment as young minds bloom and create a brighter future." />
          <link rel="canonical" href="https://insightforinnovation.in/gallery" />
        </Helmet>

        {galleryDetails.map((item, index) => <ImageCard bg={item.image.data.attributes.url} text={item.shortDescription} rotation={roateAr[index % roateAr.length]} tranlateY="10" />)}
      </div>
    </>

  )
}

export default Gallary
