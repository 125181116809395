import React from 'react'
import SEO from './SEO'

const PPolicy = () => {
    return (
        <div className='general-content'>
            <SEO
                title="Refund Policy | Insight for Innovation"
                description="Refund Policy"

                canonicalEnd="refunds-cancellations"
            />
            <h1>Refunds & Cancellations Policy</h1>

            <p>Thank you for choosing Insight for Innovation (“the Organization”). We appreciate your support and commitment to our mission. Please read our Refunds and Cancellations Policy carefully before making any purchases or registrations for our services. By accessing or using our services, you acknowledge and agree to the terms outlined in this policy.</p>
            <div className="sub-content">
                <p>1. Refunds:
                    <p className="sub-item-2">
                        <span className='index-text'>a.</span> Insight for Innovation is a non-profit organization, and all funds received are utilized to support our programs, initiatives, and operations. As such, we do not typically offer refunds for any purchases or donations made to the Organization.
                    </p>

                    <p className="sub-item-2">
                        <span className='index-text'>b.</span> In exceptional circumstances, such as technical errors or unauthorized transactions, please contact us at [contact information] to discuss your situation, and we will review it on a case-by-case basis.
                    </p>
                </p>
                <p>2. Cancellations:<br />
                    <p className="sub-item-2">
                        <span className='index-text'>a.</span> Event Registrations: If you have registered for an event organized by the Organization and need to cancel your attendance, please contact us at least [X] days before the event date. While we cannot guarantee refunds, we may consider alternative options, such as rescheduling your registration for a future event, based on availability and circumstances.
                    </p>

                    <p className="sub-item-2">
                        <span className='index-text'>b.</span> Donations: Once a donation is made to the Organization, it is considered non-refundable. If you have any concerns or questions about your donation, please contact us, and we will be happy to provide further clarification.
                    </p>
                </p>
                <p>3. Changes or Modifications:<br />
                    <p className="sub-item-2">
                        <span className='index-text'>a.</span> Insight for Innovation reserves the right to make changes or modifications to its events, programs, and services. In such cases, we will make reasonable efforts to notify affected participants or donors and provide suitable alternatives, if applicable.
                    </p>
                </p>
                <p>4. Contact Us:
                    <p className="sub-item-2">
                        If you have any questions or need assistance regarding refunds, cancellations, or any other inquiries, please reach out to us at [contact information]. We are here to help and address your concerns.
                    </p>
                </p>
                <p>Please note that by engaging with Insight for Innovation’s services, you agree to comply with this Refunds and Cancellations Policy.</p>
            </div>
        </div>

    )
}

export default PPolicy
