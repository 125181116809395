import top from "../images/footer-image.svg";
import logo from "../images/logo-footer.svg";
import location from "../images/location-normal.svg";
import call from "../images/call-normal.svg";
import email from "../images/email-normal.svg";
import s_insta from "../images/social-insta.svg";
import s_fb from "../images/social-fb.svg";
import s_linkedin from "../images/social-linkedin.svg";
import clouds from "../images/clouds.svg";
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom";

const Footer = ({updateCurrentOppor}) => {
    const navigate = useNavigate()
    return (
        <footer id="footer">
            <div className="footer-top">
                <img className="footer-image-top" src={top} alt="top" />
    
            </div>
            <div className="middle-bottom">
                <div className="footer-middle">
                    <img className="logo" src={logo} alt="" />
                    <div className="links-container">
                        <Link to="/privacy" onClick={()=>{window.scrollTo(0,0)}}>Privacy Policy</Link>
                        <Link to="/terms" onClick={()=>{window.scrollTo(0,0)}}>Terms & Conditions</Link>
                        <Link to="/about-us" onClick={()=>{window.scrollTo(0,0)}}>About us</Link>
                        <Link to="/contact-us" onClick={()=>{window.scrollTo(0,0)}}>Contact us</Link>
                        {/* <Link to="/refunds-cancellations" onClick={()=>{window.scrollTo(0,0)}}>Refund Policy</Link> */}
                        
                    </div>
                    <div className="contact-details">
                        <div className="contact-container">
                            <img src={location} alt="location" />
                            <p>Kadiyapattanam Road, Manacaud, Trivandrum, India</p>
                        </div>
                        <div className="contact-container">
                            <img src={call} alt="call" />
                            <a href="tel:+917356256446">+91 7356256446</a>
                        </div>
                        <div className="contact-container">
                            <img src={email} alt="email" />
                            <a href="mailto:admin@insightforinnovation.in">admin@insightforinnovation.in</a>
                        </div>
                    </div>

                    <div className="donate-container">
                        <p>Give hope and change lives</p>
                        <button className="primary_btn" onClick={()=>{navigate("/opportunities/sponsor");window.scrollTo(0,0);}}>Donate Now</button>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="doc-links">
                      <a href="/docs/80G-Certificate.pdf">80G Certificate</a>
                      <a href="/docs/12A-Certificate.pdf">12A Certificate</a>
                      <a href="/docs/AR-23-24.pdf" download>Annual Report 2023-24</a>
                      <a href="/docs/AR-22-23.pdf" download>Annual Report 2022-23</a>
                      <a href="/docs/AR-21-22.pdf" download>Annual Report 2021-22</a>
                      <a href="/docs/AR-20-21.pdf" download>Annual Report 2020-21</a>
                    </div>
                    <div className="social-media">
                        <a href="https://www.linkedin.com/company/insight-for-innovation/">
                            <img src={s_linkedin} alt="linkedin"/>
                        </a>
                        <a href="https://www.facebook.com/Insight4innovation/">
                            <img src={s_fb} alt="facebook"/>
                        </a>
                        <a href="https://www.instagram.com/_insight_for_innovation_/">
                            <img src={s_insta} alt="instagram"/>
                        </a>
                        
                    </div>
                    <p>©2023 Insight for Innovation. All rights reserved.</p>
                </div>

            </div>

            {/* <img className="left-cloud1" src={clouds} alt="" />
            <img className="left-cloud2" src={clouds} alt="" />
            <img className="right-cloud1" src={clouds} alt="" />
            <img className="right-cloud2" src={clouds} alt="" /> */}
            
        </footer>
    )
}

export default Footer