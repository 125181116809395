import React from 'react';
import logo_placeholder from '../images/logos/logo_placeholder.png';
import nss_kerala from '../images/logos/nss.png';
import wipro from '../images/logos/wipro-cares.png';
import cognizant from '../images/logos/cognizant-outreach.png';
import experion from '../images/logos/experion.png';
import ust from '../images/logos/ust-logo.png';
import tata_elxsi from '../images/logos/tata-elxsi.png';

const VolunteerPartners = () => {
  const volunteerPartners = [
    { name: 'National Service Scheme Kerala State Cell', logo: nss_kerala },
    { name: 'WiproCares', logo: wipro },
    { name: 'Cognizant Outreach', logo: cognizant },
    { name: 'Experion Technologies', logo: experion },
    { name: 'UST', logo: ust },
    { name: 'Tata Elxsi', logo: tata_elxsi },
  ];

  return (
    <section className="volunteer-partners wrapper">
      <h2>Volunteer Partners</h2>
      <div className="logos-container">
        {volunteerPartners.map((partner, index) => (
          <div key={index} className="logo-item">
            <img src={partner.logo || logo_placeholder} alt={partner.name} />
            <p>{partner.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default VolunteerPartners;