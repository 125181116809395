import statbg from "../images/stat-bg.svg"
import statbg1 from "../images/stat-bg-1.svg"
import av1 from "../images/avatars/avatar 1.svg"
import av2 from "../images/avatars/avatar 2.svg"
import av3 from "../images/avatars/avatar 3.svg"
import av4 from "../images/avatars/avatar 4.svg"

import cl1 from "../images/avatars/colleges 1.svg"
import cl2 from "../images/avatars/colleges 2.svg"
import cl3 from "../images/avatars/colleges 3.svg"
import cl4 from "../images/avatars/colleges 4.svg"

import cor1 from "../images/avatars/corporates 1.svg"
import cor2 from "../images/avatars/corporates 2.svg"
import cor3 from "../images/avatars/corporates 3.svg"
import cor4 from "../images/avatars/corporates 4.svg"

import { useEffect, useRef, useState } from "react"
const Stat = () => {

    const apiKey = process.env.REACT_APP_READ_API_TOKEN;

    const [studentsNo, updateStud] = useState(30000);
    const [volunteerNo, updateVol] = useState(5000);
    const [collegeNo, updateColl] = useState(46);
    const [corpNo, updateCorp] = useState(5);

    var studentArray = []
    for (var i = 0; i < studentsNo * 0.9; i = i + (studentsNo / 9)) {
        studentArray.push(parseInt(i))
    }
    studentArray.push(studentsNo)


    var volunteerArray = []
    for (var i = 0; i < volunteerNo * 0.9; i = i + (volunteerNo / 9)) {
        volunteerArray.push(parseInt(i))
    }
    volunteerArray.push(volunteerNo)

    var collegeArray = []
    for (var i = 0; i < collegeNo * 0.9; i = i + (collegeNo / 9)) {
        collegeArray.push(parseInt(i))
    }
    collegeArray.push(collegeNo)


    var corpArray = []
    for (var i = 0; i < corpNo * 0.9; i = i + (corpNo / 9)) {
        corpArray.push(parseInt(i))
    }
    corpArray.push(corpNo)

    useEffect(() => {
        const target = document.querySelector('#stat');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    changeDisplace(-91.5)
                }
            });
        });
        observer.observe(target);

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${apiKey}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://cms.insightforinnovation.in/api/stats-counters", requestOptions)
            .then(response => response.text())
            .then((result) => {
                var stat = (JSON.parse(result));
                updateCorp(stat.data[0].attributes.corporates)
                updateColl(stat.data[0].attributes.colleges)
                updateStud(stat.data[0].attributes.students)
                updateVol(stat.data[0].attributes.volunteers)
            })
            .catch(error => console.log('error', error));
    }, [])

    const [displace, changeDisplace] = useState(0)

    return (
        <>
            <section className="stats wrapper" id="stat">
                <img className="stat-bg" src={statbg} alt="" />
                <img className="stat-bg-1" src={statbg1} alt="" />
                <div className="stat-circles-container">

                    <div className="circle-text">
                        <div className="stat-circle">

                            <img src={av1} alt="" className="stv-1 smallIcon" />
                            <img src={av2} alt="" className="stv-2 smallIcon" />
                            <img src={av3} alt="" className="stv-3 smallIcon" />
                            <img src={av4} alt="" className="stv-4 smallIcon" />

                            <div className="stat-number">
                                <div className="numbers" style={{ transform: "translateY(" + displace + "%)" }}>
                                    {studentArray.map((item) => <div className="number">{item}+</div>)}
                                </div>
                                {/* <CountUp start={0} end={studentsNo} delay={100000} decimals={0} decimal="">
                                    {({ countUpRef }) => (
                                        <div>
                                        <span ref={countUpRef} />
                                        </div>
                                    )}
                                </CountUp> */}


                            </div>

                        </div>
                        <div className="text">STUDENTS</div>
                    </div>

                    <div className="circle-text">
                        <div className="stat-circle">

                            <img src={av1} alt="" className="stv-1 smallIcon" />
                            <img src={av2} alt="" className="stv-2 smallIcon" />
                            <img src={av3} alt="" className="stv-3 smallIcon" />
                            <img src={av4} alt="" className="stv-4 smallIcon" />

                            <div className="stat-number">
                                <div className="numbers" style={{ transform: "translateY(" + displace + "%)" }}>
                                    {volunteerArray.map((item) => <div className="number">{item}+</div>)}
                                </div>


                            </div>

                        </div>
                        <div className="text">VOLUNTEERS</div>
                    </div>

                    <div className="circle-text">
                        <div className="stat-circle">

                            <img src={cl1} alt="" className="stv-1 smallIcon" />
                            <img src={cl2} alt="" className="stv-2 smallIcon" />
                            <img src={cl3} alt="" className="stv-3 smallIcon" />
                            <img src={cl4} alt="" className="stv-4 smallIcon" />

                            <div className="stat-number">
                                <div className="numbers" style={{ transform: "translateY(" + displace + "%)" }}>
                                    {collegeArray.map((item) => <div className="number">{item}+</div>)}
                                </div>


                            </div>

                        </div>
                        <div className="text">SCHOOLS</div>
                    </div>

                    <div className="circle-text">
                        <div className="stat-circle">

                            <img src={cor1} alt="" className="stv-1 smallIcon" />
                            <img src={cor2} alt="" className="stv-2 smallIcon" />
                            <img src={cor3} alt="" className="stv-3 smallIcon" />
                            <img src={cor4} alt="" className="stv-4 smallIcon" />

                            <div className="stat-number">
                                <div className="numbers" style={{ transform: "translateY(" + displace + "%)" }}>
                                    {corpArray.map((item) => <div className="number">{item}+</div>)}
                                </div>


                            </div>

                        </div>
                        <div className="text">CORPORATES</div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Stat
