import React from 'react'
import SEO from './SEO'

const PPolicy = () => {
    return (
        <>
            <SEO
                title="Privacy Policy | Insight for Innovation"
                description="Privacy Policy"

                canonicalEnd="privacy"
            />
            <div className='general-content'>
                <h1>Privacy Policy</h1>
                <p>At Insight for Innovation (“the Organization”), we are committed to protecting the privacy and confidentiality of our users. This Privacy Policy outlines how we collect, use, disclose, and safeguard personal information when you use our services. By accessing or using our services, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.</p>
                <div className="sub-content">
                    <p>1. Information We Collect:</p>
                    <p className="sub-item-2">
                        <span className='index-text'>a.</span> Personal Information: We may collect personal information, such as your name, email address, postal address, phone number, and other relevant information, when you voluntarily provide it to us.<br />

                    </p>
                    <p className="sub-item-2">
                        <span className='index-text'>b.</span> Non-Personal Information: We may also collect non-personal information, such as browser type, device information, IP address, and other technical data, to enhance our services and improve user experience.
                    </p>

                    <p>2. Use of Information:<br />
                        <p className="sub-item-2"><span className='index-text'>a.</span> We may use the collected personal information to:<br />
                            – Provide and improve our services<br />
                            – Communicate with you and respond to inquiries<br />
                            – Send you newsletters, updates, and promotional materials<br />
                            – Personalize your user experience<br />
                            – Analyze and monitor usage patterns and trends
                        </p>
                    </p>
                    <p className="sub-item-2">  <span className='index-text'>b.</span> We may use the collected non-personal information for statistical purposes, to diagnose technical issues, and to improve our services.</p>
                    <p>3. Disclosure of Information:<br />
                        <p className="sub-item-2">
                            <span className='index-text'>a.</span> We may share your personal information with trusted third parties who assist us in operating our services, conducting our business, or providing services on our behalf. These third parties are obligated to keep your information confidential and are prohibited from using your personal information for any other purpose.<br />

                        </p>
                        <p className="sub-item-2">
                            <span className='index-text'>b.</span> We may disclose personal information if required by law, regulation, legal process, or government request.<br />
                        </p>
                        <p className="sub-item-2">
                            <span className='index-text'>c.</span>In the event of a merger, acquisition, or sale of all or a portion of our assets, personal information may be transferred to the acquiring entity.
                        </p>
                    </p>
                    <p>4. Data Security:<br />
                        <p className="sub-item-2"><span className='index-text'>a.</span> We implement appropriate technical and organizational measures to safeguard the security of your personal information.<br /></p>
                        <p className="sub-item-2"><span className='index-text'>b.</span> While we strive to protect your personal information, no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee absolute security.</p></p>

                    <p>5. Cookies and Tracking Technologies:<br />
                        <p className="sub-item-2">
                            <span className='index-text'>a.</span> We may use cookies and similar tracking technologies to enhance user experience, analyze trends, and gather information about user preferences. You can set your browser to refuse cookies or alert you when cookies are being sent, but some features of our services may not function properly without cookies.
                        </p>
                    </p>
                    <p>6. Third-Party Links:<br />
                        <p className="sub-item-2">
                            <span className='index-text'>a.</span> Our services may contain links to third-party websites or resources. We are not responsible for the privacy practices or the content of such third-party websites. We encourage you to review the privacy policies of those third parties before providing any personal information.
                        </p>

                    </p>
                    <p>7. Children’s Privacy:<br />
                        <p className="sub-item-2">
                            <span className='index-text'>a.</span> Our services are not directed to individuals under the age of [13/16]. We do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided us with personal information, please contact us, and we will delete such information from our records.
                        </p>

                    </p>
                    <p>8. Changes to the Privacy Policy:<br />
                        <p className="sub-item-2">
                            <span className='index-text'>a.</span> We reserve the right to modify or update this Privacy Policy at any time. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other appropriate communication channels.</p>
                    </p>

                    <p>9. Contact Us:<br />
                        <p className="sub-item-2">
                            If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at sol@insightforinnovation.in.</p>
                    </p>

                    <p>By using our services, you acknowledge that you have read, understood, and agreed to the terms of this Privacy Policy.</p>

                </div>


            </div>

        </>

    )
}

export default PPolicy
