import { useState, useEffect } from "react"
import ProjectCard from "./ProjectCard"
import img1 from "../images/projects/1.svg"
import img2 from "../images/projects/2.svg"
import img3 from "../images/projects/3.svg"
import img4 from "../images/projects/4.svg"
import ActivityCard from "./ActivityCard"
import actImg from "../images/projects/actImg.png"
import actImg1 from "../images/projects/actImg1.svg"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import ProjectPopup from "./ProjectPopup"
import { Helmet } from 'react-helmet-async';
import SEO from "./SEO"
const Projects = ({ projectDetails, currentActivities }) => {



  const colors = ["#EDC346", "#38CDC1", "#F95D5D", "#60A6F4"];
  const images = [img1, img2, img3, img4]
  const activityImages = [actImg, actImg1]
  const [maxLoad, changeMaxLoad] = useState(4);
  const [maxLoadCurrent, changeMaxLoadCurrent] = useState(2);

  const [visibleIndex, updateVisibleIndex] = useState(-1)


  return (
    <>
      <section className="projects-area">
        <div className="projects-container">
          <SEO
            title="Projects | Insight for Innovation - Empowering Underprivileged Children Through Education"
            description="Insight for Innovation is an NGO based in Trivandrum, dedicated to empowering underprivileged children through education. Our projects, such as Integrated Educational Support Program (IESP), Project Ganitham, Project Spark, Once Upon a Time, and Project Disha, aim to provide a parallel educational support system. Join us as a volunteer or partner with us to make a transformative impact."

            canonicalEnd="projects"
          />
          <h1>Projects</h1>
          <div className="pro-cards">
            {projectDetails.map((item, index) => {
              return (index < maxLoad) && <> <ProjectCard title={item.projectTitle} description={item.cardDescription} color={colors[index % colors.length]} image={images[index % images.length]} link={item.url} index={index} updateIndex={updateVisibleIndex} /> {index == visibleIndex && <ProjectPopup title={item.projectTitle} description={item.cardDescription} color={colors[index % colors.length]} image={images[index % images.length]} link={item.url} index={index} updateIndex={updateVisibleIndex} />}</>
            })}
          </div>

          {maxLoad < projectDetails.length && <div className="load-more primary_btn" onClick={() => { changeMaxLoad(projectDetails.length) }}>
            Load More
          </div>}
        </div>
      </section>

      <section className="activities-area">
        <h2>Activities</h2>
        <div className="activities-container">
          {currentActivities.map((item, index) => {
            return index < maxLoadCurrent && <ActivityCard link={item.link} color={colors[index % colors.length]} title={item.title} description={item.description} image={activityImages[index % activityImages.length]} order={index % 2 == 0 ? "first" : "second"} />
          })}

          {maxLoadCurrent < currentActivities.length && <div className="load-more primary_btn" onClick={() => { changeMaxLoadCurrent(currentActivities.length) }}>
            Load More
          </div>}
        </div>
      </section>










    </>
  )
}

export default Projects
