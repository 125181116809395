import logo_black from "../images/logo_black.svg"
import logo_horizontal from "../images/logos/Insight_horizontal_logo.svg"
import { Link } from "react-router-dom"
import dropIcon from "../images/drop_icon.svg"
import ham from "../images/ham.svg"
import SideMenu from "./SideMenu"
import { useState, useEffect } from "react"
import ganitham from "../images/project-dropdown/ganitham.svg"
import disha from "../images/project-dropdown/disha.svg"
import english from "../images/project-dropdown/english.svg"
import spark from "../images/project-dropdown/spark.svg"
import { useNavigate } from "react-router-dom"
import hoverDoodle from "../images/hover-doodle.svg"
import { useLocation } from "react-router-dom"
const Navbar = ({ projectDropdownVisibility, toggleDropdownVisibility, updateCurrentOppor }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const toggleMenu = () => {
        if (offset == 0) {
            updateOffset(initOffset)
        }
        else {
            updateOffset(0)
        }

    }
    const initOffset = -100
    const [offset, updateOffset] = useState(initOffset)
    const [activeDropdownParent, setActiveDropdownParent] = useState(false)
    const showProjectDrop = () => {
        toggleDropdownVisibility(true)
        setActiveDropdownParent(true)
    }
    const hideProjectDrop = () => {
        toggleDropdownVisibility(false)
        setActiveDropdownParent(false)
    }
    const [activeTab, updateActiveTab] = useState("")
    useEffect(() => {

        if (location.pathname.includes("/projects")) {
            updateActiveTab("Projects")
        }
        else if (location.pathname.includes("/our-story")) {
            updateActiveTab("Our Story")
        }
        else if (location.pathname.includes("/team")) {
            updateActiveTab("Team")
        }
        else if (location.pathname.includes("/opportunities")) {
            updateActiveTab("Opportunities")
        }
        else if (location.pathname.includes("/gallery")) {
            updateActiveTab("Gallery")
        }
        else if (location.pathname.includes("/contact-us")) {
            updateActiveTab("Contact us")
        }
        else {
            updateActiveTab("")
        }
    }, [location.pathname])
    return (
        <>
            <div className="nav-fixed">
                <nav className="navbar">
                    <img className="nav-logo" src={logo_black} alt="Insight" onClick={() => { navigate("/"); window.scrollTo(0, 0); updateActiveTab("") }} />
                    <img className="nav-logo-horizontal" src={logo_horizontal} alt="Insight" onClick={() => { navigate("/") }} />
                    <img className="ham" src={ham} alt="menu" onClick={toggleMenu} />
                    <div className="buttonGroup">
                        <div className={activeTab == "Projects" || projectDropdownVisibility ? "btn btn-project activeTab" : "btn btn-project"} onMouseEnter={showProjectDrop}>
                            <Link  >Projects <img src={dropIcon} alt="" /></Link>
                            <div className="hover-container">
                                <img src={hoverDoodle} alt="doodle" className={activeTab == "Projects" ? "hover-doodle-fixed" : "hover-doodle"} />
                            </div>
                            {projectDropdownVisibility && <div className="project-dropdown">

                                <div className="drop-item" onClick={() => {
                                    navigate("/projects/project-ganitham")

                                }}>
                                    <div className="cirlcle-image">
                                        <img src={ganitham} alt="ganitham" className="drop-image" />
                                    </div>

                                    <div className="drop-text-sub">
                                        <p className="main">Project Ganitham</p>
                                        <p className="sub-text">Empowering Minds with Mathematics</p>
                                    </div>
                                </div>

                                <div className="drop-item" onClick={() => {
                                    navigate("/projects/project-spark")

                                }}>
                                    <div className="cirlcle-image">
                                        <img src={spark} alt="ganitham" className="drop-image" />
                                    </div>

                                    <div className="drop-text-sub">
                                        <p className="main">Project Spark</p>
                                        <p className="sub-text">Igniting Creativity, Fostering Empathy</p>
                                    </div>
                                </div>

                                <div className="drop-item" onClick={() => {
                                    navigate("/projects/project-disha")

                                }}>
                                    <div className="cirlcle-image">
                                        <img src={disha} alt="ganitham" className="drop-image" />
                                    </div>

                                    <div className="drop-text-sub">
                                        <p className="main">Project Disha</p>
                                        <p className="sub-text">Guiding Dreams, Shaping Futures</p>
                                    </div>
                                </div>
                                <div className="drop-item" onClick={() => {
                                    navigate("/projects/project-lets-learn-english")

                                }}>
                                    <div className="cirlcle-image">
                                        <img src={english} alt="ganitham" className="drop-image" />
                                    </div>

                                    <div className="drop-text-sub">
                                        <p className="main">Let's Learn English</p>
                                        <p className="sub-text">Illuminating Minds with Language</p>
                                    </div>
                                </div>
                                <div className="view-all" onClick={() => {
                                    navigate("/projects")

                                }}>
                                    View all Projects
                                </div>


                            </div>}


                        </div>
                        <div className={activeTab == "Our Story" ? "btn activeTab" : "btn"} onMouseEnter={hideProjectDrop} onClick={() => { window.scrollTo(0, 0); }}>
                            <Link to="/our-story">Our Story</Link>
                            <div className="hover-container">
                                <img src={hoverDoodle} alt="doodle" className={activeTab == "Our Story" ? "hover-doodle-fixed" : "hover-doodle"} />
                            </div>
                        </div>
                        <div className={activeTab == "Team" ? "btn activeTab" : "btn"} onMouseEnter={hideProjectDrop} onClick={() => { window.scrollTo(0, 0); }}>
                            <Link to="/team">Team</Link>
                            <div className="hover-container">
                                <img src={hoverDoodle} alt="doodle" className={activeTab == "Team" ? "hover-doodle-fixed" : "hover-doodle"} />
                            </div>
                        </div>
                        <div className={activeTab == "Opportunities" ? "btn activeTab" : "btn"} onMouseEnter={hideProjectDrop} onClick={() => { window.scrollTo(0, 0); }}>
                            <Link onClick={() => { updateCurrentOppor("") }} to="/opportunities">Opportunities</Link>
                            <div className="hover-container">
                                <img src={hoverDoodle} alt="doodle" className={activeTab == "Opportunities" ? "hover-doodle-fixed" : "hover-doodle"} />
                            </div>
                        </div>
                        <div className={activeTab == "Gallery" ? "btn activeTab" : "btn"} onMouseEnter={hideProjectDrop} onClick={() => { window.scrollTo(0, 0); }}>
                            <Link to="/gallery">Gallery</Link>
                            <div className="hover-container">
                                <img src={hoverDoodle} alt="doodle" className={activeTab == "Gallery" ? "hover-doodle-fixed" : "hover-doodle"} />
                            </div>
                        </div>
                        <div className={activeTab == "Contact us" ? "btn activeTab" : "btn"} onMouseEnter={hideProjectDrop} onClick={() => { window.scrollTo(0, 0); }}>
                            <Link to="/contact-us">Contact us</Link>
                            <div className="hover-container">
                                <img src={hoverDoodle} alt="doodle" className={activeTab == "Contact us" ? "hover-doodle-fixed" : "hover-doodle"} />
                            </div>
                        </div>
                    </div>

                    <button className="primary_btn" onMouseEnter={hideProjectDrop} onClick={() => { navigate("/opportunities/sponsor"); window.scrollTo(0, 0); }}>Donate Now</button>

                    <SideMenu offset={offset} toggleMenu={toggleMenu} />
                </nav>
            </div>

        </>
    )
}

export default Navbar
