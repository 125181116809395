import volunteer from "../images/volunteer.svg"
import intern from "../images/intern.svg"
import OpporCard from "./OpporCard"
import sponsor from "../images/sponsor.svg"
import volPageImg from "../images/volunteer-page-img.svg"
import sponsorimg from "../images/be-sponsor.svg"
import internimg from "../images/be-intern.svg"
import VolunteerForm from "./VolunteerForm"
import LeftSection from "./LeftSection"
import { useState } from "react"
import { Helmet } from 'react-helmet-async';
import SEO from "./SEO"
const Opportunities = ({ updateCurrentOppor, currentOppor, metaTitle, metaDescription }) => {

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}

      />
      <Helmet>
        {currentOppor == "" &&
          <link rel="canonical" href="https://insightforinnovation.in/opportunities" />
        }
        {currentOppor !== "" &&
          <link rel="canonical" href={`https://insightforinnovation.in/opportunities/` + currentOppor} />
        }
      </Helmet>
      {currentOppor === "" && <div className="op-cont">
        <div className="head-des-cont">
          <h1 className="oppor_head">Opportunities</h1>
          <div className="oppor_des">Discover your chance to create meaningful change and inspire young hearts with Insight for Innovation's diverse opportunities</div>
        </div>

        <div className="oppor-cards-container">
          <OpporCard updateOppor={updateCurrentOppor} oppor="volunteer" heading={<><span style={{ color: "#0B88DF" }}>Volunteer</span> with us</>} description="Be a part of our dedicated volunteer community, empowering young minds through transformative projects. Share your time, skills, and passion, as you inspire and nurture the creativity and potential of these children." image={volunteer} />

          <OpporCard updateOppor={updateCurrentOppor} oppor="intern" heading={<><span style={{ color: "#FF7214" }}>Intern</span>  with us</>} description="Join our dynamic team as an intern and play a vital role in shaping young minds. Gain hands-on experience, contribute to impactful projects, and make a real difference in the lives of underprivileged children." image={intern} />

          <OpporCard updateOppor={updateCurrentOppor} oppor="sponsor" heading={<>Be a <span style={{ color: "#F42629" }}>Sponsor</span></>} description="Make a lasting impact on education by sponsoring our initiatives. Your support will provide underprivileged children with essential resources and opportunities, empowering them to thrive and succeed in life" image={sponsor} />
        </div>
      </div>}

      {currentOppor !== "" && <div className="oppor-bottom-container container nav-offset">

        <LeftSection heading={currentOppor === "volunteer" ? "Volunteer With us" : (currentOppor === "intern" ? "Intern With Us" : "Be a Sponsor")} description={currentOppor === "volunteer" ? "Be a part of our dedicated volunteer community, empowering young minds through transformative projects. Share your time, skills, and passion, as you inspire and nurture the creativity and potential of these children." : (currentOppor === "intern" ? "Join our dynamic team as an intern and play a vital role in shaping young minds. Gain hands-on experience, contribute to impactful projects, and make a real difference in the lives of underprivileged children." : "Make a lasting impact on education by sponsoring our initiatives. Your support will provide underprivileged children with essential resources and opportunities, empowering them to thrive and succeed in life.")} image={currentOppor === "volunteer" ? volPageImg : (currentOppor === "intern" ? internimg : sponsorimg)} updateCurrentOppor={updateCurrentOppor} />
        <VolunteerForm currentOppor={currentOppor} />
      </div>}

    </>
  )
}

export default Opportunities
