import React from 'react'
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const LeftSection = ({heading, description, image, updateCurrentOppor}) => {
  return (
    <div className="left-section">
            <Link onClick={() => { window.scrollTo(0, 0) }} to="/opportunities">
              <button className="go-back oppor-card-btn" onClick={()=>{
                updateCurrentOppor("")
              }}>
                <FaArrowLeft/>Opportunities
              </button>
            </Link>
            <h1>{heading}</h1>
            <p>{description}</p>
            <img src={image} alt="" />
            <br></br>
            
            
        </div>
  )
}

export default LeftSection
