
import ContactUs from "./components/ContactUs";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Opportunities from "./components/Opportunities";
import OurJourny from "./components/OurJourny";
import Team from "./components/Team";
import Projects from "./components/Projects"
import { useEffect, useState } from "react";
import ProjectDetails from "./components/ProjectDetails";
import Gallary from "./components/Gallary";
import Footer from "./components/Footer";
import PPolicy from "./components/PPolicy";
import Terms from "./components/Terms";
import Refunds from "./components/Refunds";
import About from "./components/About";
import { HelmetProvider } from 'react-helmet-async';
import PageNotFound from "./components/PageNotFound";

function App() {
  const [projectDropdownVisibility, toggleDropdownVisibility] = useState(false)
  const [currentOppor, updateCurrentOppor] = useState("")

  const apiKey = process.env.REACT_APP_READ_API_TOKEN

  useEffect(() => {

    //Get projects api

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${apiKey}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    var projectDetailsAll;

    fetch("https://cms.insightforinnovation.in/api/projects?populate=*", requestOptions)
      .then(response => response.text())
      .then(result => {
        projectDetailsAll = (JSON.parse(result));
        const filtered = (projectDetailsAll.data.map((item) => {
          return item.attributes;
        }))
       
        updateProject(filtered)

      })
      .catch(error => console.log('error', error));

    //Just in
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${apiKey}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://cms.insightforinnovation.in/api/just-ins", requestOptions)
      .then(response => response.text())
      .then((result) => {
        var parsed=JSON.parse(result).data.map(item=>item.attributes)
     
        updateCurrentActivities(parsed)
      })
      .catch(error => console.log('error', error));


      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${apiKey}`);
      
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      
      fetch("https://cms.insightforinnovation.in/api/galleries?populate=*", requestOptions)
        .then(response => response.text())
        .then(result => {
          var galleryAll = (JSON.parse(result));
          const filteredG = (galleryAll.data.map((item) => {
          return item.attributes;
        }))
        updateGallery(filteredG)
        })
        .catch(error => console.log('error', error));

  }, [])

  const [gallery,updateGallery]=useState([])
  const [projectDetails, updateProject] = useState([

  ])
  const [currentActivities, updateCurrentActivities] = useState([
    
  ])
  return (
    <HelmetProvider>
    <div className="App">
      <BrowserRouter>
        <Navbar toggleDropdownVisibility={toggleDropdownVisibility} projectDropdownVisibility={projectDropdownVisibility} updateCurrentOppor={updateCurrentOppor}/>
        <div className="app-container" onMouseEnter={() => { toggleDropdownVisibility(false) }}>
          <Routes>
            <Route path="/" element={<Home currentActivities={currentActivities} />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/our-story" element={<OurJourny />} />
            <Route path="/opportunities" element={<Opportunities updateCurrentOppor={updateCurrentOppor} currentOppor={currentOppor} metaTitle="Join Us and Make a Difference | Insight for Innovation - Empowering Young Minds Through Education" metaDescription="Explore the various opportunities to be part of the Insight for Innovation community. Intern with us, volunteer with our transformative projects or become a sponsor to empower young minds and make a lasting impact in the field of education. Together, let's shape a brighter future for underprivileged children."/>} />
            <Route path="/opportunities/sponsor" element={<Opportunities updateCurrentOppor={updateCurrentOppor} currentOppor="sponsor" metaTitle="Donate | Insight for Innovation- Empowering Our Kids Through Education" metaDescription="Be a part of Insight for Innovation by sponsoring us | Empowering Young Minds Through Education"/>} />
            <Route path="/opportunities/intern" element={<Opportunities updateCurrentOppor={updateCurrentOppor} currentOppor="intern" metaTitle="NGO Internships in Trivandrum | Insight for Innovation- Empowering Our Kids Through Education" metaDescription="Explore our exciting internship opportunities at Insight for Innovation. Gain valuable experience, contribute to transformative projects, and be a catalyst for positive change in the lives of underprivileged children."/>} />
            <Route path="/opportunities/volunteer" element={<Opportunities updateCurrentOppor={updateCurrentOppor} currentOppor="volunteer" metaTitle="NGO Volunteer Opportunities | Insight for Innovation- Empowering Our Kids Through Education" metaDescription="Discover fulfilling volunteer roles in Trivandrum with Insight for Innovation. Join our passionate community, share your skills and time, and make a meaningful impact in nurturing and inspiring young hearts."/>} />
            <Route path="/gallery" element={<Gallary galleryDetails={gallery} />} />
            <Route path="/team" element={<Team />} />
            <Route path="/projects" element={<Projects currentActivities={currentActivities} projectDetails={projectDetails} />} />


            {projectDetails.map((item) => {

              return <>
                <Route path={"/projects/" + item.url} element={
                  <ProjectDetails title={item.projectTitle} descriptionLong={item.longDescription} descriptionShort={item.cardDescription} imageList={item.images} mainImage={item.mainImage ? item.mainImage.data.attributes.url : item.images.data[0].attributes.url} school_no={item.statStudents} vol_no={item.statVolunteers} updateCurrentOppor={updateCurrentOppor} metaDes={item.metaDescription} link={item.url}/>

                } />
              </>
            })}
    
            <Route path="/privacy" element={<PPolicy/>}/>
            <Route path="/terms" element={<Terms/>}/>
            <Route path="/refunds-cancellations" element={<Refunds/>}/>
            <Route path="/about-us" element={<About/>}/>
            <Route path="*" element={<PageNotFound/>} />
          </Routes>
          <Footer updateCurrentOppor={updateCurrentOppor}/>
        </div>
      </BrowserRouter>
    </div>
    </HelmetProvider>
  );
}

export default App;
