import { Link } from "react-router-dom"
import idea from "../images/idea-black-bg.svg"
import home from "../images/sideMenu/home.svg"
import projects from "../images/sideMenu/projects.svg"
import contact from "../images/sideMenu/contact-us.svg"
import gallery from "../images/sideMenu/gallery.svg"
import story from "../images/sideMenu/our-story.svg"
import oppor from "../images/sideMenu/opportunities.svg"
import team from "../images/sideMenu/team.svg"
import close from "../images/sideMenu/close.svg"
const SideMenu = ({ offset ,toggleMenu}) => {
    return (
        <div style={{ transform: `translateX(${offset}%)` }} className="side-menu-container">

            <div className="side-button-group" >
                <div className="btn" onClick={()=>{toggleMenu()}}>
                    <img src={home} alt="icon" />
                    <Link to="/">Home</Link>
                </div>
                <div className="btn" onClick={()=>{toggleMenu()}}>
                    <img src={projects} alt="icon" />
                    <Link to="/projects">Projects</Link>
                </div>
                <div className="btn" onClick={()=>{toggleMenu()}}>
                    <img src={story} alt="icon" />
                    <Link to="/our-story">Our Story</Link>
                </div>
                <div className="btn" onClick={()=>{toggleMenu()}}>
                    <img src={team} alt="icon" />
                    <Link to="/team">Team</Link>
                </div>
                <div className="btn" onClick={()=>{toggleMenu()}}>
                    <img src={oppor} alt="icon" />
                    <Link to="/opportunities">Opportunities</Link>
                </div>
                <div className="btn" onClick={()=>{toggleMenu()}}>
                    <img src={gallery} alt="icon" />
                    <Link to="/gallery">Gallery</Link>
                </div>
                <div className="btn" onClick={()=>{toggleMenu()}}>
                    <img src={contact} alt="icon" />
                    <Link to="/contact-us">Contact us</Link>
                </div>
                <img className="idea-black" src={idea} alt="idea" />
            </div>

            <img className="close-btn" src={close} alt="" onClick={()=>{toggleMenu()}} />
        </div>
    )
}

export default SideMenu
