import { useState } from "react"


const VolunteerForm = ({ currentOppor }) => {

    const apiKey = process.env.REACT_APP_FORMS_API_TOKEN;

    function is_valid_email(email) {
        var pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/;
        return pattern.test(email);
    }

    function is_valid_phone_number(phone_number) {
        var pattern = /^(\+\d{1,3})?\d{10}$/;
        return pattern.test(phone_number);
    }

    const [radioValue, changeRadio] = useState(true)
    const [firstName, changeFirstName] = useState("")
    const [lastName, changeLastName] = useState("")
    const [email, changeEmail] = useState("")
    const [mobileNumber, changeMobileNumber] = useState("")
    const [student, changeStudentState] = useState("")
    const [fieldOfStudy, changeFieldOfStudy] = useState("")
    const [institutionName, changeInstitutionName] = useState("")
    const [selfDescription, changeselfDescription] = useState("")
    const [submitText, changeSubmitText] = useState("Submit")
    const [errorText, updateError] = useState([])
    const radioChanged = (e) => {
        changeRadio(e.target.value)
    }
    const submit = (e) => {
        e.preventDefault();
        var errorTemp = []
        var myHeaders = new Headers();

        myHeaders.append("Authorization", `bearer ${apiKey}`);
        myHeaders.append("Content-Type", "application/json");
        var studentStat = "0";
        if (radioValue == "yes") {
            studentStat = "1"
        }
        else {
            studentStat = "0"
        }
        if (firstName.trim().length == 0) {
            errorTemp.push("First name cannot be empty.")
        }

        if (!is_valid_phone_number(mobileNumber)) {
            if (mobileNumber.length != 0) {
                errorTemp.push("Please enter a valid phone number.")
            }
            else {
                errorTemp.push("Mobile number cannot be empty.")
            }



        }

        if (!is_valid_email(email)) {
            if (email.length != 0) {
                errorTemp.push("Please enter a valid Email.")
            }
            else {
                errorTemp.push("Email cannot be empty.")
            }
        }


        updateError(errorTemp)
        if (errorTemp.length > 0) {
            return;
        }
        if (currentOppor == "volunteer" || currentOppor == "intern") {
            var raw = JSON.stringify({
                "data": {
                    "firstName": firstName,
                    "lastName": lastName,
                    "email": email,
                    "mobileNumber": mobileNumber,
                    "student": studentStat,
                    "fieldOfStudy": fieldOfStudy,
                    "institutionName": institutionName,
                    "selfDescription": selfDescription
                }
            });
        }
        else {
            var raw = JSON.stringify({
                "data": {
                    "firstName": firstName,
                    "lastName": lastName,
                    "email": email,
                    "mobileNumber": mobileNumber,
                    "currentlyEmployed": studentStat,
                    "fieldOfStudy": fieldOfStudy,

                }
            });
        }


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        var url;
        if (currentOppor == "volunteer") {
            url = "https://cms.insightforinnovation.in/api/volunteer-forms";
        }
        else if (currentOppor == "intern") {
            url = "https://cms.insightforinnovation.in/api/intern-forms";
        }
        else {
            url = "https://cms.insightforinnovation.in/api/donate-forms"
        }
        fetch(url, requestOptions)
            .then(response => response.text())
            .then((result) => {
                console.log(result)
                if (JSON.parse(result).data == null) {

                }
                else {
                    changeSubmitText("Submitted!")
                    updateError([])
                    setTimeout(() => { changeSubmitText("Submit") }, 2000)
                    changeFirstName("")
                    changeLastName("")
                    changeEmail("")
                    changeMobileNumber("")
                    changeStudentState("")
                    changeFieldOfStudy("")
                    changeInstitutionName("")
                    changeselfDescription("")


                    document.getElementById("left-radio").checked = false
                    document.getElementById("right-radio").checked = false

                }
            })
            .catch(error => {
                console.log('error', error);
                alert("Something went wrong. Please try again later...")
            });

    }
    return (
        <div className="form-heading-cont">
            <h1>{currentOppor == "intern" ? "Register Now" : (currentOppor == "volunteer" ? "Be a Volunteer" : "Donate now")}</h1>
            <form className="vl-form" onSubmit={submit}>

                <div className="row-flex">
                    <div className="input-container">

                        <input type="text" placeholder="First Name" value={firstName} onChange={(e) => { changeFirstName(e.target.value) }} />
                    </div>

                    <div className="input-container">

                        <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => { changeLastName(e.target.value) }} />
                    </div>
                </div>

                <div className="row-flex">
                    <div className="input-container">

                        <input type="text" placeholder="Email ID" value={email} onChange={(e) => { changeEmail(e.target.value) }} />

                    </div>


                </div>
                <div className="input-container">

                    <input type="text" placeholder="Mobile Number" value={mobileNumber} onChange={(e) => { changeMobileNumber(e.target.value) }} />
                </div>


                <div className="row-flex">
                    <div className="radio-container">
                        <div className="input-container">
                            <label>{currentOppor !== "sponsor" ? "Are you a student?" : "Are you currently employed?"}</label>
                            <div className="radio-label-cont">
                                <input type="radio" id="left-radio" name="student" onChange={radioChanged} value="yes" /><label htmlFor="left-radio" className="radio-label-yes radio-label">Yes</label>
                                <input type="radio" id="right-radio" name="student" onChange={radioChanged} value="no" /><label htmlFor="right-radio" className="radio-label-no radio-label">No</label>

                            </div>
                        </div>
                    </div>
                </div>
                {radioValue == "yes" && currentOppor !== "sponsor" && <>
                    <div className="input-container">

                        <input type="text" placeholder="Field of Study" value={fieldOfStudy} onChange={(e) => { changeFieldOfStudy(e.target.value) }} />
                    </div>

                    <div className="input-container">

                        <input type="text" placeholder="Institution Name" value={institutionName} onChange={(e) => { changeInstitutionName(e.target.value) }} />
                    </div>
                </>}

                {currentOppor !== "sponsor" && <div className="input-container">

                    <textarea placeholder="Describe yourself (Skills,experience...)" value={selfDescription} onChange={(e) => { changeselfDescription(e.target.value) }}></textarea>
                </div>}
                <br />
                <div className="error">
                    {errorText.map(item => <div>{item}</div>)}
                </div>
                <input type="submit" value={submitText} />
            </form>
        </div>
    )
}

export default VolunteerForm
