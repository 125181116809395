
import school from "../images/projects/school.svg"
import kid from "../images/projects/kid.svg"
import doodle from "../images/projects/doodle.png"
import Footer from "./Footer"
import { useNavigate } from "react-router-dom"
import { Helmet } from 'react-helmet-async';
import SEO from "./SEO"
const domain = "https://cms.insightforinnovation.in"

const ProjectDetails = ({ title, descriptionShort, descriptionLong, mainImage, imageList, school_no, vol_no, updateCurrentOppor, metaDes, link }) => {
    const navigate = useNavigate()
    return (
        <div className="project-details-container">
            <SEO
                title={title}
                description={metaDes}

                canonicalEnd={link}
            />
            <div className="top">

                <div className="main-image" style={{ backgroundImage: "url('" + domain + mainImage + "')" }}>

                </div>
                <div className="right">
                    <img className="doodle" src={doodle} alt="" />
                    <h1 className="main-title">{title}</h1>
                    <div className="top-dec">{descriptionShort}</div>
                    <div className="number-detail-box">
                        <div className="img-details">
                            <img src={school} alt="School" />
                            <p>NO OF STUDENTS</p>
                            <h1>{school_no}+</h1>
                        </div>

                        <div className="img-details">
                            <img src={kid} alt="School" />
                            <p>NO OF VOLUNTEERS</p>
                            <h1>{vol_no}+</h1>
                        </div>
                    </div>
                </div>

            </div>

            <div className="middle">
                {descriptionLong}

            </div>

            <div className="bottom-image-container">
                {(imageList.data.length) > 0 ? imageList.data.map(item => <div className="bottom-image" style={{ backgroundImage: "url('" + domain + item.attributes.url + "')" }}>
                </div>) : ""}

            </div>

            <div className="volunteer-container">
                <div className="left">
                    <h3>Want to be a part of Project Ganitham?</h3>
                    <p>Register your interest now!</p>
                </div>
                <div className="primary_btn vol" onClick={() => { navigate("/opportunities"); updateCurrentOppor("volunteer"); window.scrollTo(0, 0) }}>Be a Volunteer</div>
            </div>

        </div>

    )
}

ProjectDetails.defaultProps = {
    imageList: { data: [] }
}

export default ProjectDetails
