import bg from "../images/activity-bg.svg"

import bg2 from "../images/activity/bg2.svg"
const CurrentActivity = ({ title, description, link, image, linkTitle }) => {
    return (
        <div className="activity">
            {/* <img className="activity-bg" src={bg} alt="bg" /> */}
            <div id="activity-image-text">
                <div className="text-btn-container fade-in-fade-out">
                    <h2>{title}</h2>
                    <div className="description-cta">
                        <p>{description}</p>
                        {link || linkTitle !== "null" ? <button className="know-more oppor-card-btn" onClick={() => { window.open(link) }}>
                            {linkTitle}
                        </button>
                            :
                            ""}
                    </div>

                </div>
                <div className="right-image">
                    <img src={image} alt="bg-image" />
                </div>
            </div>

        </div>
    )
}

export default CurrentActivity
