import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({title, description, twitterName, canonicalEnd}) {
    return (
        <Helmet>
        { /* Standard metadata tags */ }
        <title>{title}</title>
        <meta name='description' content={description} />
        {canonicalEnd ? 
        <link rel="canonical" href={`https://insightforinnovation.in/${canonicalEnd}`} /> : ""
        }
        { /* End standard metadata tags */ }
        { /* Facebook tags */ }
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="/images/meta_og_image.jpg" />
        { /* End Facebook tags */ }
        { /* Twitter tags */ }
        <meta name="twitter:creator" content={twitterName} />
        <meta name="twitter:card" content="website" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="/images/meta_twitter_image.jpg" />
        { /* End Twitter tags */ }
        </Helmet>
    )
    }