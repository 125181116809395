import React from 'react'
import journy from "../images/our journey.png"
import { Helmet } from 'react-helmet-async';
import SEO from './SEO';
const OurJourny = () => {
  return (
    <section className="our-story nav-offset">
      <SEO
        title="Our Story | Insight for Innovation - Empowering Our Kids Through Education"
        description="Discover the inspiring journey of the NGO based in Trivandrum Insight for Innovation, a transformative force in education. From Dr. APJ Abdul Kalam's vision of an educational revolution to the unwavering commitment of our founder and CEO, Hema Gopalakrishnan, our story is one of passion, impact, and empowerment. Join us on this remarkable path of shaping young lives through education."

        canonicalEnd="our-story"
      />
      <h1 className="oppor_head">Our Story</h1>
      <div className='oj-container'>

        <img className='our-journy' src={journy} alt="" />


      </div>
    </section>

  )
}

export default OurJourny
