

const ImageCard = ({bg,rotation,tranlateY,text}) => {
    const domain="https://cms.insightforinnovation.in"
    
  return (
    <div className="image-card" style={{transform:"translateY("+tranlateY+"px) rotate("+rotation+"deg)",backgroundImage:"url('"+domain+bg+"')"}}>
      <div className="card-text">
        {text}
      </div>
    </div>
  )
}

export default ImageCard
