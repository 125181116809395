

const TestimonialCard = ({ mainText, name, pos, event, image }) => {
    var imageUrl = "";
    if (image && image.data != null) {
        imageUrl = image.data.attributes?.formats.thumbnail.url;
    }
    var baseURL = "https://cms.insightforinnovation.in";
    var finalImgUrl = baseURL + imageUrl;
    return (
        <div className="testimonial-card">
            <p className="main-text">{mainText}</p>

            <div className="testi-bottom">
                <div className="testi-image">
                    <img src={imageUrl ? finalImgUrl : "/user-testimonial.png"} alt={name} />
                </div>
                <div className="name-pos">
                    <p className="name">{name}</p>
                    <p className="pos">{pos}</p>
                    {event &&
                        <div className="event-container">
                            <div className="event-box">EVENT</div>
                            <div className="event-name">{event}</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default TestimonialCard